import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Benevole_Photo.jpg';
        case 'Presse':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Presse_Photo.jpg';
        case 'Direction':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Direction.jpg';
        case 'Staff':
            return 'https://assets.recrewteer.com/badges/nuitsChampagne/Badge_Staff_Photo.jpg';
        default:
            return '';
    }
};

interface IBadgeNuitsChampagneProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeNuitsChampagne = (props: IBadgeNuitsChampagneProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.population;
    const color = population === 'Direction' ? 'black' : 'white';

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$lato',
                position: 'relative'
            }}
        >
            <Box
                height={120}
                width={120}
                css={{
                    borderRadius: '$1',
                    left: '138px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '184px'
                }}
            >
                <img
                    src={
                        ui.picture?.url ||
                        'https://assets.recrewteer.com/badges/nuitsChampagne/photo.png'
                    }
                    height="100%"
                    width="100%"
                />
            </Box>

            <Flex
                direction="column"
                align="center"
                width={397}
                css={{
                    color,
                    fontSize: '24px',
                    fontWeight: '900',
                    lineHeight: '28px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '320px'
                }}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                textAlign="center"
                width={397}
                css={{
                    color,
                    fontSize: '20px',
                    fontWeight: '500',
                    position: 'absolute',
                    top: '380px'
                }}
            >
                {ui.fields.cf39015}
            </Box>

            <Box
                height={88}
                width={88}
                css={{
                    background: 'white',
                    borderRadius: '$1',
                    left: '154px',
                    padding: '6px',
                    position: 'absolute',
                    top: '432px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=88&width=88`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
