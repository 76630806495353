import { Page } from 'common-front/src/components/page/page';
import { DelegationAccreditationsLoader } from 'common-front/src/delegations/accreditations/show/delegationAccreditationsLoader';
import { DelegationDocuments } from 'common-front/src/delegations/accreditations/show/delegationDocuments';
import { DelegationMembers } from 'common-front/src/delegations/members/show/delegationMembers';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationQuery, Feature } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import {
    DelegationParams,
    DelegationsPaths,
    DelegationUserParams
} from 'common/src/util/paths/delegationsPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useOrganizationContext } from '../../organization/organizationContext';
import { UserOverlayEvent, UserOverlayOrganization } from '../../users/overlay/userOverlay';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationHeader } from './delegationHeader';

interface IDelegationProps {
    organization: DelegationQuery['organization'];

    reload(): void;
}

export const Delegation = (props: IDelegationProps) => {
    const {
        history,
        params: { organizationId, eventId, delegationId },
        translate
    } = useHeavent();

    const { hasFeature } = useOrganizationContext();
    const isEventAdmin = useEventContext().event?.permissions.isEventAdmin;

    const getDelegationParams = (isRouteComponent: boolean): DelegationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: eventId ? (isRouteComponent ? ':eventId' : eventId) : undefined,
        delegationId: isRouteComponent ? ':delegationId' : delegationId
    });
    const getDelegationUserParams = (
        isRouteComponent: boolean,
        userInfoId: UserInfoIdPathParam
    ): DelegationUserParams => ({
        ...getDelegationParams(isRouteComponent),
        userInfoId: isRouteComponent ? ':userInfoId' : userInfoId
    });

    return (
        <Page.Root>
            <DelegationHeader delegation={props.organization.delegation} reload={props.reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab path={DelegationsPaths.DELEGATION_DASHBOARD(getDelegationParams(false))}>
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab path={DelegationsPaths.DELEGATION_MEMBERS(getDelegationParams(false))}>
                        {translate(
                            'membres_125561',
                            props.organization.delegation.numberOfResources
                        )}
                    </Tab>

                    {eventId && hasFeature(Feature.Accreditation) && (
                        <Tab
                            path={DelegationsPaths.DELEGATION_ACCREDITATIONS(
                                getDelegationParams(false)
                            )}
                        >
                            {translate('accr_ditations_39450')}
                        </Tab>
                    )}

                    {eventId && props.organization.allowDelegationSpaceDownload && (
                        <Tab
                            path={DelegationsPaths.DELEGATION_DOCUMENTS(getDelegationParams(false))}
                        >
                            {translate('documents_87028')}
                        </Tab>
                    )}
                </TabList>

                <TabPanel path={DelegationsPaths.DELEGATION_DASHBOARD(getDelegationParams(true))}>
                    <DelegationDashboard />
                </TabPanel>

                <TabPanel path={DelegationsPaths.DELEGATION_MEMBERS(getDelegationParams(true))}>
                    <Page.Content>
                        <DelegationMembers
                            basePath={DelegationsPaths.DELEGATION_MEMBERS(
                                getDelegationParams(false)
                            )}
                            canEditMembers={true}
                            customFields={props.organization.customFields.nodes}
                            delegation={props.organization.delegation}
                            getEditPath={(userInfoId, formId) =>
                                eventId
                                    ? HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                          organizationId,
                                          eventId,
                                          delegationId,
                                          userInfoId,
                                          formId
                                      )
                                    : HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION(
                                          organizationId,
                                          delegationId,
                                          userInfoId
                                      )
                            }
                            isEventAdmin={isEventAdmin}
                            onClick={(memberId) => {
                                history.push(
                                    DelegationsPaths.DELEGATION_MEMBER_INFORMATION(
                                        getDelegationUserParams(false, memberId)
                                    )
                                );
                            }}
                            showAccreditActions={true}
                            showDeleteFromEvent={isEventAdmin}
                            showDocumentsDownload={true}
                            userOverlay={() => {
                                const getBasePath = (
                                    userInfoId: UserInfoIdPathParam,
                                    isRouteComponent: boolean = false
                                ) =>
                                    DelegationsPaths.DELEGATION_MEMBER(
                                        getDelegationUserParams(isRouteComponent, userInfoId)
                                    );
                                const onCloseAndDelete = () => {
                                    history.push(
                                        DelegationsPaths.DELEGATION_MEMBERS(
                                            getDelegationParams(false)
                                        )
                                    );
                                };

                                return eventId ? (
                                    <UserOverlayEvent
                                        getBasePath={getBasePath}
                                        onClose={onCloseAndDelete}
                                        onDelete={() => {
                                            onCloseAndDelete();

                                            props.reload();
                                        }}
                                        onUpdateState={props.reload}
                                    />
                                ) : (
                                    <UserOverlayOrganization
                                        getBasePath={getBasePath}
                                        onClose={onCloseAndDelete}
                                        onDelete={() => {
                                            onCloseAndDelete();

                                            props.reload();
                                        }}
                                    />
                                );
                            }}
                        />
                    </Page.Content>
                </TabPanel>

                <TabPanel
                    path={DelegationsPaths.DELEGATION_ACCREDITATIONS(getDelegationParams(true))}
                >
                    <Page.Content>
                        <DelegationAccreditationsLoader isEventAdmin={isEventAdmin} />
                    </Page.Content>
                </TabPanel>

                <TabPanel path={DelegationsPaths.DELEGATION_DOCUMENTS(getDelegationParams(true))}>
                    <Page.Content>
                        <DelegationDocuments
                            delegationsSpaceCustomBadges={
                                props.organization.delegationsSpaceCustomBadges
                            }
                        />
                    </Page.Content>
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
