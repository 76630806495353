import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackground = (acronyms: string[]) => {
    if (acronyms.includes('BLEU_CLAIR')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/bleu_clair_verso.jpg';
    } else if (acronyms.includes('BLEU_FONCE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/bleu_fonce_verso.jpg';
    } else if (acronyms.includes('JAUNE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/jaune_verso.jpg';
    } else if (acronyms.includes('ORANGE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/orange_verso.jpg';
    } else if (acronyms.includes('VERT')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/vert_verso.jpg';
    } else if (acronyms.includes('VIOLET')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/versos/violet_verso.jpg';
    } else {
        return '';
    }
};

interface IBadgeParis20kmBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeParis20kmBack = (props: IBadgeParis20kmBackProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const acronyms = vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym!);

    return (
        <Flex
            height={476}
            width={368}
            css={{
                background: `url(${getBackground(acronyms)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Flex
                align="center"
                justify="center"
                css={{
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '700',
                    height: '39px',
                    left: '29px',
                    position: 'absolute',
                    top: '31px',
                    width: '40px'
                }}
            >
                {userInfo.paris20kmTaille}
            </Flex>
        </Flex>
    );
};
