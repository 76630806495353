import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    AccreditationState,
    Delegation,
    FormId,
    SegmentCustomFieldFragment,
    SortDirection,
    UsersInfoId,
    UsersInfosSortAttributes,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { DelegationMembersSegmentsService } from 'common/src/vo/segments/delegationMembersSegmentsService';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Blank } from '../../../components/blank/blank';
import { useDelegationMembersQuery } from '../../../generated/graphqlHooks';
import { useHeavent } from '../../../hooks/useHeavent';
import { Svgs } from '../../../util/assets';
import { DelegationMemberRow } from './delegationMemberRow';
import { DelegationMembersFilters } from './delegationMembersFilters';

interface IDelegationMembersProps {
    basePath: string;
    canEditMembers: boolean;
    customFields: SegmentCustomFieldFragment[];
    delegation: Pick<Delegation, 'name' | 'formId' | 'columns'>;
    deleteFromEvent?: boolean;
    isEventAdmin?: boolean;
    showAccreditActions: boolean;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;
    userOverlay?: React.ReactNode;

    getEditPath(userInfoId: UsersInfoId, formId: FormId): string;
    onClick?(memberId: UsersInfoId): void;
}

export const DelegationMembers = ({
    basePath,
    canEditMembers,
    customFields,
    delegation,
    deleteFromEvent,
    getEditPath,
    isEventAdmin,
    onClick,
    showAccreditActions,
    showDeleteFromEvent,
    showDocumentsDownload,
    userOverlay
}: IDelegationMembersProps) => {
    const {
        params: { organizationId, eventId, delegationId },
        translate
    } = useHeavent();
    const delegationMembersSegmentsService = useService(DelegationMembersSegmentsService);
    const [search, searchDebounced, setSearch] = useStateDebounce('');
    const [assignmentState, setAssignmentState] =
        React.useState<Emptyable<VolunteerRegistrationState>>(null);
    const [accreditationState, setAccreditationState] =
        React.useState<Emptyable<AccreditationState>>(null);
    const [limit, setLimit] = React.useState(25);
    const [offset, setOffset] = React.useState(0);
    const [sort, _setSort] = React.useState<Sort<UsersInfosSortAttributes> | null>({
        attribute: UsersInfosSortAttributes.Name,
        direction: SortDirection.Asc
    });
    const includes = React.useMemo(
        () => ({
            includeAccreditations:
                delegation.columns.includes(VolunteerDefaultColumns.Accreditations) ||
                delegation.columns.includes(VolunteerDefaultColumns.WishedAccreditationSlots),
            includePositions: delegation.columns.includes(VolunteerDefaultColumns.Positions),
            includeWishedPositions: delegation.columns.includes(
                VolunteerDefaultColumns.WishedPositions
            )
        }),
        [delegation.columns]
    );
    const { data, isLoading, reload } = useDelegationMembersQuery({
        ...includes,
        organizationId,
        eventId,
        delegationId,
        name: isNonEmptyString(searchDebounced) ? searchDebounced : null,
        assignmentState: assignmentState ? assignmentState : undefined,
        accreditationState: accreditationState ? accreditationState : undefined,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.usersInfos);
    const setSort = (newSort: Sort<UsersInfosSortAttributes> | null) => {
        _setSort(newSort);
        setOffset(0);
    };
    const columns = React.useMemo(() => {
        if (data.organization) {
            const possibleColumns =
                delegationMembersSegmentsService.getDelegationMembersPossibleColumns(customFields, {
                    forEvent: !!eventId
                });

            return delegation.columns.flatMap((slug) => {
                const column = possibleColumns.find((c) => c.slug === slug);

                return column ? [column] : [];
            });
        } else {
            return [];
        }
    }, [customFields, delegation, data.organization]);

    return (
        <>
            <TableFilters
                filters={
                    <DelegationMembersFilters
                        accreditationState={accreditationState}
                        assignmentState={assignmentState}
                        eventId={eventId}
                        search={search}
                        setAccreditationState={setAccreditationState}
                        setAssignmentState={setAssignmentState}
                        setOffset={setOffset}
                        setSearch={setSearch}
                    />
                }
                headerCells={
                    <>
                        {columns.map((column) => {
                            if (column.slug === VolunteerDefaultColumns.Name) {
                                return (
                                    <HeaderCellSort
                                        key={column.slug}
                                        attribute={UsersInfosSortAttributes.Name}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('nom_complet_du_71872')}
                                    </HeaderCellSort>
                                );
                            } else if (
                                column.slug === VolunteerDefaultColumns.AccreditationState ||
                                column.slug === VolunteerDefaultColumns.VolunteerRegistrationState
                            ) {
                                return (
                                    <HeaderCell key={column.slug} width={240}>
                                        {column.name}
                                    </HeaderCell>
                                );
                            } else {
                                return <HeaderCell key={column.slug}>{column.name}</HeaderCell>;
                            }
                        })}

                        <HeaderCell width={100} />
                    </>
                }
                limit={limit}
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : isNonEmptyArray(data.organization.usersInfos.nodes) ? (
                        (data.organization?.usersInfos.nodes ?? []).map((member) => (
                            <DelegationMemberRow
                                key={member.id}
                                basePath={basePath}
                                canEditMembers={canEditMembers}
                                columns={columns}
                                customBadges={data.organization.delegationsSpaceCustomBadges}
                                customFields={customFields}
                                deleteFromEvent={deleteFromEvent}
                                editPath={getEditPath(member.id, delegation.formId)}
                                formId={delegation.formId}
                                isEventAdmin={isEventAdmin}
                                member={member}
                                reload={reload}
                                showAccreditActions={showAccreditActions}
                                showDeleteFromEvent={showDeleteFromEvent}
                                showDocumentsDownload={showDocumentsDownload}
                                onClick={onClick}
                            />
                        ))
                    ) : (
                        <Row css={{ height: '200px' }}>
                            <Cell>
                                <Blank
                                    imageSrc={Svgs.FormsElementsBlank}
                                    title={translate('aucun_membre_da_58845')}
                                />
                            </Cell>
                        </Row>
                    )
                }
                setLimit={setLimit}
                setOffset={setOffset}
                showLimits={true}
                title={translate('liste_des_membr_44592', delegation.name)}
                totalCount={totalCount}
            />

            {userOverlay && (
                <Switch>
                    <Route
                        path={[
                            DelegationsPaths.DELEGATION_MEMBER({
                                organizationId: ':organizationId',
                                eventId: null,
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId'
                            }),
                            DelegationsPaths.DELEGATION_MEMBER({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId'
                            })
                        ]}
                    >
                        {userOverlay}
                    </Route>
                </Switch>
            )}
        </>
    );
};
