import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionId,
    VolunteersRegistrationsSlotInput
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { UserInfoFields } from 'common/src/vo/field';
import { canSelectV2 } from 'common/src/vo/position';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Select } from '../../../designSystem/components/select/select';

interface IFormMissionsRankedPositionsProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    index: number;
    options: FormMissionsOptionsFragment;
    positionsIds: PositionId[];
    prefix: string;
    slots: VolunteersRegistrationsSlotInput[];
    userInfoFields: UserInfoFields;

    change(name: string, value: any): void;
}

export const FormMissionsRankedPositions = (props: IFormMissionsRankedPositionsProps) => {
    const translate = useTranslate();
    const positions = React.useMemo(() => props.categories.flatMap((category) => sortBy(
                category.positions.filter((position) => canSelectV2(position, props.userInfoFields, props.slots, props.options)),
                (p) => p.name
            ).map((position) => (
                    <option key={position.id} value={position.id}>
                        {position.name}
                    </option>
                ))), [props.categories]);

    return (
        <>
            <Select
                label={translate('souhait_n_1_47211', props.index + 1)}
                value={props.positionsIds?.[props.index] ?? -1}
                onChange={(newPositionId: PositionId) => {
                    props.change(`${props.prefix}positionsIds[${props.index}]`, newPositionId);
                }}
                shouldParseAsInt={true}
            >
                <option value={-1}>{translate('choisir_une_mis_90627')}</option>

                {positions}
            </Select>

            {props.index !== props.options.positionLimit - 1 && <Spacer height="4" />}
        </>
    );
};
