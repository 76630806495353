import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditInfosQuery,
    AccreditSlotsQuery,
    DelegationAccreditationSlot
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditAccreditation } from './accreditAccreditation';

interface IAccreditAccreditationsProps {
    delegation: AccreditInfosQuery['event']['volunteerRegistration']['delegation'];
    slots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
}

export const AccreditAccreditations = ({ delegation, slots }: IAccreditAccreditationsProps) => {
    const slotsByAccreditation = React.useMemo(
        () =>
            sortBy(Object.values(groupBy(slots, (s) => s.accreditation.id)), (slots) =>
                slots[0].accreditation.name.toLowerCase()
            ),
        [slots]
    );

    return (
        <Flex
            direction="column"
            gap="2"
            css={{
                background: '$gray100',
                borderTop: '1px solid ^$gray200',
                padding: '$4'
            }}
        >
            {slotsByAccreditation.map((slots) => {
                const accreditation = slots[0].accreditation;
                const delegationAccreditations = delegation?.accreditationsSlots.filter(
                    (accred) =>
                        accred.accreditationSlot.accreditation.id === accreditation.id &&
                        accred.maxResources !== undefined
                );

                return (
                    <AccreditAccreditation
                        key={accreditation.id}
                        accreditation={accreditation}
                        delegationAccreditations={
                            isNonEmptyArray(delegationAccreditations)
                                ? (delegationAccreditations as DelegationAccreditationSlot[])
                                : undefined
                        }
                        slots={slots}
                    />
                );
            })}
        </Flex>
    );
};
