import * as React from 'react';
import { AccreditationsCategories } from '../v2/accreditationsCategories';
import { AccreditationsRightPanelCategory } from './accreditationsRightPanelCategory';
import {
    AccreditationState,
    useAccreditationsRightPanelContext
} from './accreditationsRightPanelContext';

interface IAccreditationsRightPanelAssignedTabProps {
    state: AccreditationState;
}

export const AccreditationsRightPanelAssignedTab = (
    props: IAccreditationsRightPanelAssignedTabProps
) => {
    const { assignedCategories, wishedCategories } = useAccreditationsRightPanelContext();
    const categories = props.state === 'assigned' ? assignedCategories : wishedCategories;

    return (
        <AccreditationsCategories>
            {categories.map((category) => (
                <AccreditationsRightPanelCategory
                    key={category.id}
                    category={category}
                    state={props.state}
                />
            ))}
        </AccreditationsCategories>
    );
};
