import { Empty } from 'common-front/src/components/empty/empty';
import { HorizontalTabs } from 'common-front/src/designSystem/components/horizontalTabs/horizontalTabs';
import { Tab } from 'common-front/src/designSystem/components/horizontalTabs/tab';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignType } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useCampaignQuery } from '../../generated/graphqlHooks';
import { CampaignContextProvider, useCampaignContext } from './campaignContext';
import { CampaignEmail } from './campaignEmail';
import { CampaignEmailPreview } from './campaignEmailPreview';
import { CampaignTextMessage } from './campaignTextMessage';
import { CampaignTextMessagePreview } from './campaignTextMessagePreview';

const Campaign = () => {
    const translate = useTranslate();
    const { organizationId, campaignId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const {
        campaignsPath,
        campaignRoutePath,
        campaignDashboardPath,
        campaignDashboardRoutePath,
        campaignPreviewPath,
        campaignPreviewRoutePath
    } = useCampaignContext();
    const { data, loader } = useCampaignQuery({ organizationId, campaignId });

    return (
        loader || (
            <Flex direction="column" width={1} css={{ padding: '$7' }}>
                <Flex align="center" gap="3">
                    <Box
                        color="gray900"
                        fontSize="textXl"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(campaignsPath);
                        }}
                    >
                        <I icon="arrow-left" />
                    </Box>

                    <Box
                        color="gray900"
                        fontSize="displayXs"
                        fontWeight="medium"
                        css={{ flex: '1' }}
                    >
                        {data.organization.campaign.name}
                    </Box>

                    <Box width={320}>
                        <HorizontalTabs
                            selectedItem={
                                location.pathname.endsWith('dashboard') ? 'dashboard' : 'preview'
                            }
                            onChange={(item) => {
                                if (item === 'dashboard') {
                                    history.push(campaignDashboardPath);
                                } else {
                                    history.push(campaignPreviewPath);
                                }
                            }}
                        >
                            <Tab item="dashboard">{translate('tableau_de_bord_24627')}</Tab>
                            <Tab item="preview">{translate('aper_u_25595')}</Tab>
                        </HorizontalTabs>
                    </Box>
                </Flex>

                <Spacer height="7" />

                <Switch>
                    <Route
                        path={campaignDashboardRoutePath}
                        children={
                            data.organization.campaign.campaignType === CampaignType.Email ? (
                                <CampaignEmail campaign={data.organization.campaign} />
                            ) : (
                                <CampaignTextMessage campaign={data.organization.campaign} />
                            )
                        }
                    />

                    <Route
                        path={campaignPreviewRoutePath}
                        children={
                            data.organization.campaign.campaignType === CampaignType.Email ? (
                                <CampaignEmailPreview campaign={data.organization.campaign} />
                            ) : (
                                <CampaignTextMessagePreview campaign={data.organization.campaign} />
                            )
                        }
                    />

                    <Route
                        path={campaignRoutePath}
                        children={<Empty path={campaignDashboardPath} replace={true} />}
                    />
                </Switch>
            </Flex>
        )
    );
};

export const EventCampaign = () => {
    const { organizationId, eventId, campaignId } = useParams();

    return (
        <CampaignContextProvider
            campaignRoutePath={HeaventPaths.CAMPAIGN(':organizationId', ':eventId', ':campaignId')}
            campaignDashboardPath={HeaventPaths.CAMPAIGN_DASHBOARD(
                organizationId,
                eventId,
                campaignId
            )}
            campaignDashboardRoutePath={HeaventPaths.CAMPAIGN_DASHBOARD(
                ':organizationId',
                ':eventId',
                ':campaignId'
            )}
            campaignDashboardUserRoutePath={HeaventPaths.CAMPAIGN_DASHBOARD_USER(
                ':organizationId',
                ':eventId',
                ':campaignId',
                ':userInfoId'
            )}
            campaignPreviewPath={HeaventPaths.CAMPAIGN_PREVIEW(organizationId, eventId, campaignId)}
            campaignPreviewRoutePath={HeaventPaths.CAMPAIGN_PREVIEW(
                ':organizationId',
                ':eventId',
                ':campaignId'
            )}
            campaignsPath={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
            isOrganizationCampaign={false}
            getCampaignDashboardUserPath={(userInfoId, isRouteComponent) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER(
                    isRouteComponent ? ':organizationId' : organizationId,
                    isRouteComponent ? ':eventId' : eventId,
                    isRouteComponent ? ':campaignId' : campaignId,
                    userInfoId
                )
            }
            getCampaignDashboardUserInfosPath={(userInfoId) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    eventId,
                    campaignId,
                    userInfoId
                )
            }
            getUserInformationsPath={(userInfoId) =>
                HeaventPaths.CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    eventId,
                    campaignId,
                    userInfoId
                )
            }
            getVolunteersSegmentPath={(segmentId) =>
                HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)
            }
        >
            <Campaign />
        </CampaignContextProvider>
    );
};

export const OrganizationCampaign = () => {
    const { organizationId, campaignId } = useParams();

    return (
        <CampaignContextProvider
            campaignRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN(':organizationId', ':campaignId')}
            campaignDashboardPath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD(
                organizationId,
                campaignId
            )}
            campaignDashboardRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD(
                ':organizationId',
                ':campaignId'
            )}
            campaignDashboardUserRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER(
                ':organizationId',
                ':campaignId',
                ':userInfoId'
            )}
            campaignPreviewPath={HeaventPaths.COMMUNITY_CAMPAIGN_PREVIEW(
                organizationId,
                campaignId
            )}
            campaignPreviewRoutePath={HeaventPaths.COMMUNITY_CAMPAIGN_PREVIEW(
                ':organizationId',
                ':campaignId'
            )}
            campaignsPath={HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)}
            isOrganizationCampaign={true}
            getCampaignDashboardUserPath={(userInfoId) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER(
                    organizationId,
                    campaignId,
                    userInfoId
                )
            }
            getCampaignDashboardUserInfosPath={(userInfoId) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    campaignId,
                    userInfoId
                )
            }
            getUserInformationsPath={(userInfoId) =>
                HeaventPaths.COMMUNITY_CAMPAIGN_DASHBOARD_USER_INFORMATIONS(
                    organizationId,
                    campaignId,
                    userInfoId
                )
            }
            getVolunteersSegmentPath={(segmentId) =>
                HeaventPaths.COMMUNITY_USERS_SEGMENT(organizationId, segmentId)
            }
        >
            <Campaign />
        </CampaignContextProvider>
    );
};
