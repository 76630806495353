import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { AccreditationsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { AccreditationsSegmentsService } from 'common/src/vo/segments/accreditationsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { AccreditationsContent } from './accreditationsContent';
import { AccreditationsContextProvider } from './accreditationsContext';

interface IAccreditationsProps {
    event: AccreditationsSegmentsQuery['event'];

    reload(): void;
}

export const Accreditations = (props: IAccreditationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        localEvent,
        updateLocalEvent
    } = useEventContext();
    const segmentService = useService(AccreditationsSegmentsService);
    const filters = React.useMemo(
        () => segmentService.getAccreditationsFilters(props.event),
        [props.event]
    );
    const possibleColumns = React.useMemo(
        () => segmentService.getAccreditationsPossibleColumns(),
        []
    );

    return (
        <AccreditationsContextProvider eventId={eventId} organizationId={organizationId}>
            <SegmentsGrid
                eventId={eventId}
                filters={filters}
                filtersButtonText={translate('filtrer_les_acc_57840')}
                filtersSubtitle={translate('appliquer_des_f_18351')}
                hideSegments={props.event.numberOfAccreditations === 0}
                initialLimit={localEvent?.accreditationsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localEvent?.areAccreditationsSegmentsOpen ?? true}
                isAdmin={isEventAdmin}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                segmentId={segmentId}
                segmentType={SegmentType.Accreditations}
                segmentsFolders={props.event.segmentsFolders}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.ACCREDITATIONS_SEGMENT(organizationId, eventId, id)
                }
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalEvent({ accreditationsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalEvent({ accreditationsSegmentId: null });

                    history.push(HeaventPaths.ACCREDITATIONS(organizationId, eventId));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalEvent({ areAccreditationsSegmentsOpen: areSegmentsOpen });
                }}
                reload={props.reload}
                setLimit={(accreditationsLimit) => {
                    updateLocalEvent({ accreditationsLimit });
                }}
            >
                <AccreditationsContent
                    numberOfAccreditations={props.event.numberOfAccreditations}
                />
            </SegmentsGrid>
        </AccreditationsContextProvider>
    );
};
