import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'PARTENAIRE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/VIP@3x.jpg';
        case 'PARTENAIRE - Fast Pass':
            return '';
        case 'VIP LOGES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/vip_loges.png';
        case 'VIP LOGES Fast Pass':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/vip_loges.png';
        case 'ADMINISTRATEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ADMIN@3x.png';
        case 'MÉDIAS - JOURNALISTE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/MEDIAS_JOURNALISTE@3x.png';
        case 'MÉDIAS - PHOTOGRAPHE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/MEDIAS_PHOTOGRAPHE@3x.png';
        case 'BÉNÉVOLES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/STAFF@3x.png';
        case 'CHAUFFEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/CHAUFFEUR@3x.png';
        case 'RAMASSEUR DE BALLES':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ramasseurs.png';
        case 'JUGE DE LIGNE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/juge_lignes.png';
        case 'OFFICIEL ATP':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/OFFICIEL@3x.png';
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/ORGANISATION@3x.png';
        case 'PRESTATAIRE':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/PRESTATAIRE@3x.png';
        case 'JOUEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/PLAYER@3x.png';
        case 'COACH':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/COACH@3x.png';
        case 'GUEST':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/GUEST@3x.png';
        case 'SUPERVISEUR':
            return 'https://assets.recrewteer.com/badges/openbrest/v2/supervisor_atp.png';
        default:
            return '';
    }
};

interface IBadgeOpenBrestProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeOpenBrest = (props: IBadgeOpenBrestProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const firstLine =
        ui.blotPopulation === 'OFFICIEL ATP' ||
        ui.blotPopulation === 'ORGANISATION' ||
        ui.blotPopulation === 'ADMINISTRATEUR' ||
        ui.blotPopulation === 'PRESTATAIRE' ||
        ui.blotPopulation === 'MÉDIAS - PHOTOGRAPHE' ||
        ui.blotPopulation === 'MÉDIAS - JOURNALISTE' ||
        ui.blotPopulation === 'VIP LOGES' ||
        ui.blotPopulation === 'VIP LOGES Fast Pass' ||
        ui.blotPopulation === 'PARTENAIRE'
            ? `${ui.firstName} ${ui.lastName}`
            : ui.firstName;
    const secondLine =
        ui.blotPopulation === 'OFFICIEL ATP' || ui.blotPopulation === 'ORGANISATION'
            ? ui.fields.cf36132
            : ui.blotPopulation === 'ADMINISTRATEUR' ||
              ui.blotPopulation === 'PRESTATAIRE' ||
              ui.blotPopulation === 'MÉDIAS - PHOTOGRAPHE' ||
              ui.blotPopulation === 'MÉDIAS - JOURNALISTE' ||
              ui.blotPopulation === 'VIP LOGES' ||
              ui.blotPopulation === 'VIP LOGES Fast Pass' ||
              ui.blotPopulation === 'PARTENAIRE'
            ? ui.fields.cf31319
            : ui.lastName.toUpperCase();

    return (
        <>
            <Flex
                height={331}
                width={208}
                css={{
                    background: `url(${getBackground(ui.blotPopulation)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$sharpGrotesk',
                    position: 'relative'
                }}
            >
                <Box
                    height={80}
                    width={80}
                    css={{
                        borderRadius: '$1',
                        left: '61px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '169px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: 'white',
                        fontWeight: '600',
                        position: 'absolute',
                        top: '285px'
                    }}
                >
                    {firstLine}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: 'white',
                        fontWeight: '600',
                        position: 'absolute',
                        top: '301px'
                    }}
                >
                    {secondLine}
                </Box>
            </Flex>

            <Flex
                height={331}
                width={208}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/openbrest/v2/VERSO@3x.png) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
            >
                <Box
                    height={90}
                    width={90}
                    css={{
                        left: '59px',
                        position: 'absolute',
                        top: '115px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </>
    );
};
