import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { useAccreditationDuplicate } from 'common-front/src/vo/accreditation';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { AccreditationQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { DeleteAccreditation } from '../deleteAccreditation';

interface IAccreditationHeaderProps {
    accreditation: AccreditationQuery['event']['accreditation'];
}

export const AccreditationHeader = (props: IAccreditationHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const { setReferrerPath } = useReferrerContext();
    const accreditationDuplicate = useAccreditationDuplicate();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Flex align="center" gap="3">
            <Box
                color="gray900"
                fontSize="textXl"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    history.push(HeaventPaths.ACCREDITATIONS(organizationId, eventId));
                }}
            >
                <I icon="arrow-left" />
            </Box>

            <Box color="gray900" fontSize="displayXs" fontWeight="medium" css={{ flex: '1' }}>
                {props.accreditation.name}{' '}
                {isNonEmptyString(props.accreditation.acronym)
                    ? `(${props.accreditation.acronym})`
                    : ''}
            </Box>

            <Button
                color="white"
                to={HeaventPaths.EDIT_ACCREDITATION(organizationId, eventId, accreditationId)}
                onClick={setReferrerPath}
            >
                {translate('_diter_62574')}
            </Button>

            <Button
                onClick={() => {
                    history.push(
                        HeaventPaths.ACCREDITATION_ACCREDIT(
                            organizationId,
                            eventId,
                            accreditationId
                        )
                    );
                }}
            >
                {translate('accr_diter_un_m_50869')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="clone"
                        onClick={async () => {
                            await accreditationDuplicate(props.accreditation.id);
                        }}
                    >
                        {translate('dupliquer_28256')}
                    </ItemIcon>

                    <ItemIcon
                        icon="trash-can"
                        color="red"
                        onClick={(e) => {
                            closeOtherDropdowns(e.target);
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isDeleteOpen && (
                <DeleteAccreditation
                    accreditation={props.accreditation}
                    onSuccess={() => {
                        history.push(HeaventPaths.ACCREDITATIONS(organizationId, eventId));
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </Flex>
    );
};
