import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { usePositionDuplicate } from 'common-front/src/vo/position';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { PositionQuery } from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { DeletePosition } from '../deletePosition';

interface IPositionHeaderProps {
    position: PositionQuery['event']['position'];
}

export const PositionHeader = (props: IPositionHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { setReferrerPath } = useReferrerContext();
    const { user } = useUserContext();
    const { isPositionCategoryAdmin, isPositionAdmin, localEvent } = useEventContext();
    const positionDuplicate = usePositionDuplicate();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Flex align="center" gap="3">
            <Box
                color="gray900"
                fontSize="textXl"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    history.push(
                        localEvent?.positionBackPath ??
                            HeaventPaths.POSITIONS(organizationId, eventId)
                    );
                }}
            >
                <I icon="arrow-left" />
            </Box>

            <Box color="gray900" fontSize="displayXs" fontWeight="medium" css={{ flex: '1' }}>
                {props.position.name}{' '}
                {isNonEmptyString(props.position.acronym) ? `(${props.position.acronym})` : ''}
            </Box>

            <Button
                color="white"
                download={`${props.position.name}-position-sheet`}
                to={{
                    pathname: getDocumentFullPath(props.position.links.positionSheetLink, 'pdf', {
                        lang: user?.language
                    })
                }}
                target="_blank"
            >
                {translate('t_l_charger_la_16259')}
            </Button>

            {isPositionAdmin(props.position.positionCategoryId, props.position.id) && (
                <Button
                    to={HeaventPaths.EDIT_POSITION(organizationId, eventId, props.position.id)}
                    onClick={setReferrerPath}
                >
                    {translate('_diter_la_missi_90442')}
                </Button>
            )}

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    {isPositionCategoryAdmin(props.position.positionCategoryId) && (
                        <ItemIcon
                            icon="clone"
                            onClick={async () => {
                                await positionDuplicate(props.position.id);
                            }}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>
                    )}

                    <ItemIcon
                        icon="arrow-down-to-bracket"
                        download={`${props.position.name}-position-planning`}
                        to={{
                            pathname: getDocumentFullPath(
                                props.position.links.positionPlanningLink,
                                'pdf',
                                {
                                    lang: user?.language
                                }
                            )
                        }}
                        target="_blank"
                    >
                        {translate('t_l_charger_le_06050')}
                    </ItemIcon>

                    {isPositionCategoryAdmin(props.position.positionCategoryId) && (
                        <ItemIcon
                            icon="trash-can"
                            color="red"
                            onClick={async () => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    )}
                </Menu>
            </Dropdown>

            {isDeleteOpen && (
                <DeletePosition
                    position={props.position}
                    onSuccess={() => {
                        history.push(HeaventPaths.POSITIONS(organizationId, eventId));
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </Flex>
    );
};
