import { ErrorBoundaryContextProvider } from 'common-front/src/components/errorBoundary/errorBoundaryContext';
import { MainLoader } from 'common-front/src/components/mainLoader/mainLoader';
import { MediaQueryProvider } from 'common-front/src/components/mediaQuery/mediaQueryContext';
import { UserContext } from 'common-front/src/userContext';
import { FrontDependenciesProvider } from 'common-front/src/util/dependencies/frontDependenciesProvider';
import { ReferrerContextProvider } from 'common-front/src/util/referrerContext';
import * as React from 'react';
import { NotificationContextProvider } from './notifications/notificationContext';

interface IAppContainerProps {
    children: React.ReactNode;
}

export const AppContainer = ({ children }: IAppContainerProps) => (
    <React.StrictMode>
        <MainLoader
            render={(userContext) => (
                <UserContext.Provider value={userContext}>
                    <FrontDependenciesProvider>
                        <MediaQueryProvider>
                            <ErrorBoundaryContextProvider>
                                <NotificationContextProvider>
                                    <ReferrerContextProvider>
                                        <>{children}</>
                                    </ReferrerContextProvider>
                                </NotificationContextProvider>
                            </ErrorBoundaryContextProvider>
                        </MediaQueryProvider>
                    </FrontDependenciesProvider>
                </UserContext.Provider>
            )}
        />
    </React.StrictMode>
);
