import * as React from 'react';
import { getColorPalette } from '../../util/color';
import { isNonEmptyString } from '../../util/string';
import { Flex } from './flex';
import { I, IIcon, IStyle } from './i';
import { CSS, styled } from './stitches';

const _Badge = styled('div', {
    alignItems: 'center',
    borderRadius: '$1',
    display: 'flex',
    fontWeight: '$medium',
    gap: '$1',
    px: '$2',
    userSelect: 'none',
    height: 'fit-content',
    '&:has(.h-badge-count)': {
        paddingRight: '$1'
    },
    '& > .h-badge-count': {
        borderRadius: '3px',
        height: 'calc(100% - 8px)',
        marginLeft: '$1',
        px: '6px'
    },
    variants: {
        size: {
            sm: {
                height: '22px',
                fontSize: '12px',
                '& > .h-badge-count': {
                    fontSize: '10px'
                }
            },
            md: {
                height: '24px',
                fontSize: '12px',
                '& > .h-badge-count': {
                    fontSize: '10px'
                }
            },
            lg: {
                height: '28px',
                fontSize: '14px',
                '& > .h-badge-count': {
                    fontSize: '12px'
                }
            }
        },
        cursor: {
            default: {
                cursor: 'default'
            },
            pointer: {
                cursor: 'pointer'
            },
            text: {
                cursor: 'text'
            }
        },
        ellipsis: {
            true: {
                maxWidth: '80%',
                '& .h-badge-content': {
                    ellipsis: true,
                    flex: '1'
                }
            }
        }
    },
    defaultVariants: {
        size: 'sm',
        cursor: 'default'
    }
});

export type IBadgeSize = 'sm' | 'md' | 'lg';

export type IBadgeColor = 'gray' | 'primary' | 'error' | 'warning' | 'success' | 'pink' | 'white';

export type IBadgeCursor = 'default' | 'pointer' | 'text';

interface IBadgeProps {
    children?: React.ReactNode;
    color?: IBadgeColor | string;
    count?: number | string;
    css?: CSS;
    cursor?: IBadgeCursor;
    ellipsis?: true;
    leftIcon?: IIcon;
    leftIconStyle?: IStyle;
    rightIcon?: IIcon;
    size?: IBadgeSize;

    onClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onLeftIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onContentClick?(e: React.MouseEvent<HTMLDivElement>): void;
    onRightIconClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

export const Badge = React.forwardRef((props: IBadgeProps, ref: React.Ref<HTMLDivElement>) => {
    const colorCss = React.useMemo(() => {
        const color = props.color || 'gray';
        const palette = getColorPalette(color);

        return color === 'gray'
            ? {
                  background: '$gray100',
                  color: '$gray700',
                  '& > .h-badge-left-icon': {
                      color: '$gray500'
                  },
                  '& > .h-badge-count': {
                      background: '$gray200'
                  }
              }
            : color === 'white'
            ? {
                  background: 'white',
                  border: '1px solid $gray300',
                  '& > .h-badge-count': {
                      background: '$gray100'
                  }
              }
            : {
                  background: palette['50'],
                  color: palette['700'],
                  '& > .h-badge-left-icon': {
                      color: palette['500']
                  },
                  '& > .h-badge-count': {
                      background: palette['100']
                  }
              };
    }, [props.color]);

    return (
        <_Badge
            ref={ref}
            size={props.size}
            color={props.color}
            cursor={props.cursor}
            ellipsis={props.ellipsis}
            onClick={props.onClick}
            css={{
                ...(colorCss as any),
                ...(props.css as any)
            }}
        >
            {props.leftIcon && (
                <Flex
                    className="h-badge-left-icon"
                    css={{
                        cursor: props.onLeftIconClick ? 'pointer' : 'inherit'
                    }}
                    onClick={(e) => {
                        props.onLeftIconClick?.(e);
                    }}
                >
                    <I icon={props.leftIcon} iconStyle={props.leftIconStyle} />
                </Flex>
            )}

            {props.children && (
                <Flex
                    className="h-badge-content"
                    css={{ flex: '1' }}
                    onClick={(e) => {
                        props.onContentClick?.(e);
                    }}
                >
                    {props.children}
                </Flex>
            )}

            {props.rightIcon && (
                <Flex
                    css={{
                        cursor: props.onRightIconClick ? 'pointer' : 'inherit'
                    }}
                    onClick={(e) => {
                        props.onRightIconClick?.(e);
                    }}
                >
                    <I icon={props.rightIcon} />
                </Flex>
            )}

            {(typeof props.count === 'number' || isNonEmptyString(props.count)) && (
                <Flex align="center" className="h-badge-count">
                    {props.count}
                </Flex>
            )}
        </_Badge>
    );
});
