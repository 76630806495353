import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { ToggleText as ToggleTextComp } from 'common-front/src/designSystem/components/toggle';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { addOrRemove } from 'common/src/util/array';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import {
    useOrganizationDelegationSpaceSettingsQuery,
    useOrganizationDelegationSpaceSettingsUpdateMutation
} from '../../generated/graphqlHooks';

export const OrganizationDelegationSpaceSettings = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { data, loader } = useOrganizationDelegationSpaceSettingsQuery({
        organizationId
    });
    const { mutate } = useOrganizationDelegationSpaceSettingsUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    allowDelegationSpaceDownload: data.organization.allowDelegationSpaceDownload,
                    delegationsSpaceCustomBadges: data.organization.delegationsSpaceCustomBadges
                }}
                onSubmit={async (values) => {
                    await mutate({
                        organizationId,
                        delegationSpaceSettings: values
                    });
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                    <>
                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('team_area_setti_29458')}
                        </Box>

                        <Box color="gray500" fontWeight="medium">
                            {translate('customize_the_s_92329')}
                        </Box>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex gap="7">
                            <Box color="gray700" fontWeight="medium" width={320}>
                                {translate('allow_documents_96106')}
                            </Box>

                            <ToggleText name="allowDelegationSpaceDownload">
                                {translate('allow_delegatio_08622')}
                            </ToggleText>
                        </Flex>

                        {values.allowDelegationSpaceDownload && (
                            <>
                                <HorizontalSpacerSeparator height="5" />

                                <Flex gap="7">
                                    <Box color="gray700" fontWeight="medium" width={320}>
                                        {translate('documents_dispo_46996')}
                                    </Box>

                                    <Flex
                                        direction="column"
                                        css={{
                                            border: '1px solid $gray200',
                                            borderRadius: '$2',
                                            flex: '1',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {data.organization.customBadges.map((badge) => (
                                            <Flex
                                                key={badge}
                                                align="center"
                                                gap="3"
                                                css={{
                                                    borderBottom: '1px solid $gray200',
                                                    padding: '$4 $6',
                                                    '&:last-child': {
                                                        borderBottom: 'none'
                                                    }
                                                }}
                                            >
                                                <Flex
                                                    align="center"
                                                    justify="center"
                                                    height={40}
                                                    width={40}
                                                    css={{
                                                        background: '$blueGray100',
                                                        borderRadius: '12px',
                                                        color: '$blueGray700'
                                                    }}
                                                >
                                                    <I icon="file-lines" />
                                                </Flex>

                                                <Box
                                                    font="gray900 textSm medium"
                                                    css={{ flex: '1' }}
                                                >
                                                    {getDisplayName(badge)}
                                                </Box>

                                                <ToggleTextComp
                                                    value={values.delegationsSpaceCustomBadges.includes(
                                                        badge
                                                    )}
                                                    onChange={(newValue) => {
                                                        form.change(
                                                            'delegationsSpaceCustomBadges',
                                                            addOrRemove(
                                                                values.delegationsSpaceCustomBadges,
                                                                badge,
                                                                newValue
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {translate('document_t_l_ch_56704')}
                                                </ToggleTextComp>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </Flex>
                            </>
                        )}

                        <HorizontalSpacerSeparator height="5" />

                        <Flex>
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('enregistrer_06519')}
                            </Button>
                        </Flex>
                    </>
                )}
            />
        )
    );
};
