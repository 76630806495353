import { TextInput } from 'common-front/src/designSystem/components/textInput';
import {
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationUpdateCell';

interface ICommunityUserNumberCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserNumberCell = (props: ICommunityUserNumberCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);
    const initialValue = React.useMemo(
        () => fieldService.getValue<number>(props.customField, props.userInfo) || 0,
        [props.customField, props.userInfo]
    );

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={initialValue}
            organizationId={props.organizationId}
            userInfoId={props.userInfo.id}
            valueString={valueString}
            reload={props.reload}
        >
            {(value, setValue) => (
                <TextInput
                    label={props.customField.name}
                    shouldParseAsInt={true}
                    value={value}
                    onChange={setValue}
                />
            )}
        </UserInfoUpdateCell>
    );
};
