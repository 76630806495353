import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { EventState } from 'common/src/generated/types';
import { HeaventEnv } from 'common/src/heaventEnv';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useOrganizationContext } from '../organizationContext';

export function getMenuItemStyles(isActive: boolean) {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return { color: theme.colors.gray800.value, opacity: isActive ? '1' : '0.6' };
        case HeaventEnv.Mso:
            return { color: 'white' };
        case HeaventEnv.Recrewteer:
            return { color: 'white', opacity: isActive ? '1' : '0.6' };
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
}

export const EventsMenu = () => {
    const { translate } = useHeavent();
    const { pathname } = useLocation();
    const { unsetReferrerPath } = useReferrerContext();
    const { organization, selectedEventId } = useOrganizationContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const isActive =
        pathname === HeaventPaths.EVENTS(organization.id) ||
        pathname.match(/\/events/) !== null ||
        pathname.match(/\/event\/\d+/) !== null;
    const events = sortBy(
        organization.events.nodes.filter((e) => e.state === EventState.Ongoing),
        (e) => e.name
    );
    const selectedEvent = organization.events.nodes.find((e) => e.id === selectedEventId);

    return (
        <Dropdown onStateChange={setIsOpen}>
            <Trigger>
                <Flex
                    align="center"
                    gap="2"
                    aria-expanded={isOpen}
                    aria-label={translate('ouvrir_la_liste_52670')}
                    aria-selected={isOpen}
                    role="button"
                    css={{
                        ...getMenuItemStyles(isActive),
                        px: '$4',
                        blockSize: '100%'
                    }}
                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        document.getElementById('events-menu')?.focus();
                    }}
                >
                    {selectedEvent ? selectedEvent.name : translate('_v_nements_53672')}
                    {isOpen ? <I icon="angle-up" /> : <I icon="angle-down" />}
                </Flex>
            </Trigger>

            <Menu
                id="events-menu"
                aria-orientation="vertical"
                placement="bottom-start"
                offset={-10}
                maxHeight={300}
            >
                <Flex
                    direction="column"
                    css={{
                        flex: '1',
                        overflowY: 'auto'
                    }}
                >
                    {events.map((event) => (
                        <Item
                            key={event.id}
                            onClick={(e) => {
                                unsetReferrerPath();
                                closeOtherDropdowns(e.target);
                                document.getElementById('main')?.focus();
                            }}
                            to={HeaventPaths.EVENT(organization.id, event.id)}
                        >
                            {event.name}
                        </Item>
                    ))}
                </Flex>

                <Separator direction="horizontal" color="gray100" />

                <Item
                    onClick={(e) => {
                        unsetReferrerPath();
                        closeOtherDropdowns(e.target);
                        document.getElementById('main')?.focus();
                    }}
                    to={HeaventPaths.EVENTS(organization.id)}
                >
                    {translate('tous_les_v_nem_16033')}
                </Item>
            </Menu>
        </Dropdown>
    );
};
