import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormMissionsOptionsFragment,
    FormMissionsQuery,
    PositionsCategoryId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Select } from '../../../designSystem/components/select/select';

interface IFormMissionsRankedCategoriesProps {
    categories: FormMissionsQuery['event']['positionsCategories'];
    index: number;
    options: FormMissionsOptionsFragment;
    positionsCategoriesIds: PositionsCategoryId[];
    prefix: string;

    change(name: string, value: any): void;
}

export const FormMissionsRankedCategories = (props: IFormMissionsRankedCategoriesProps) => {
    const translate = useTranslate();
    const categories = React.useMemo(() => sortBy(props.categories, (c) => c.name).map((category) => (
                <option key={category.id} value={category.id}>
                    {category.name}
                </option>
            )), [props.categories]);

    return (
        <>
            <Select
                label={translate('souhait_n_1_47211', props.index + 1)}
                value={props.positionsCategoriesIds?.[props.index] ?? -1}
                onChange={(newPositionCategoryId: PositionsCategoryId) => {
                    props.change(
                        `${props.prefix}positionsCategoriesIds[${props.index}]`,
                        newPositionCategoryId
                    );
                }}
                shouldParseAsInt={true}
            >
                <option value={-1}>{translate('choisir_une_mis_90627')}</option>

                {categories}
            </Select>

            {props.index !== props.options.positionLimit - 1 && <Spacer height="4" />}
        </>
    );
};
