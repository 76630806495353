import { uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment, FormId } from '../../../generated/types';
import { A7_SIZES } from '../../../util/pdf';

const AUTRES_FORM_ID = 2163 as FormId;

interface IBadgeSalamandreA7BackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeSalamandreA7Back = (props: IBadgeSalamandreA7BackProps) => {
    const filledFormsIds = props.event.volunteerRegistration.userInfo.formsUsersInfos.map(
        (fui) => fui.formId
    );

    return (
        <Flex
            direction="column"
            height={A7_SIZES['96dpi'].height - 5}
            width={A7_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/salamandre/2024/verso_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                paddingBottom: '24px',
                paddingTop: '56px',
                px: '16px'
            }}
        >
            <Box
                css={{
                    color: '#336998',
                    fontSize: '10px',
                    fontWeight: '700'
                }}
            >
                Renseignements complémentaires
            </Box>

            <Spacer height="3" />

            {filledFormsIds.includes(AUTRES_FORM_ID) ? (
                props.event.volunteerRegistration.positionsSlotsUsersInfos.length > 0 && (
                    <>
                        <Box
                            css={{
                                color: '#5a9e9f',
                                fontSize: '8px',
                                fontWeight: '600'
                            }}
                        >
                            Responsable de la mission
                        </Box>

                        <Spacer height="1" />

                        <Box
                            css={{
                                color: '#336998',
                                fontSize: '10px',
                                fontWeight: '500'
                            }}
                        >
                            {uniq(
                                props.event.volunteerRegistration.positionsSlotsUsersInfos.flatMap(
                                    (psui) =>
                                        psui.position.leaders.map(
                                            (leader) =>
                                                `${leader.userInfo.name} : ${
                                                    leader.userInfo.phone?.internationalFormat ??
                                                    '-'
                                                }`
                                        )
                                )
                            ).join(', ')}
                        </Box>

                        <Spacer height="4" />
                    </>
                )
            ) : (
                <>
                    <Box
                        css={{
                            color: '#5a9e9f',
                            fontSize: '8px',
                            fontWeight: '600'
                        }}
                    >
                        Responsable des bénévoles
                    </Box>

                    <Spacer height="1" />

                    <Box
                        css={{
                            color: '#336998',
                            fontSize: '10px',
                            fontWeight: '500'
                        }}
                    >
                        Anika Andenmatten : 079 689 52 51
                    </Box>

                    <Spacer height="4" />
                </>
            )}

            <Box
                css={{
                    color: '#5a9e9f',
                    fontSize: '8px',
                    fontWeight: '600'
                }}
            >
                Police
            </Box>

            <Spacer height="1" />

            <Box
                css={{
                    color: '#336998',
                    fontSize: '10px',
                    fontWeight: '500'
                }}
            >
                117
            </Box>

            <Spacer height="4" />

            <Box
                css={{
                    color: '#5a9e9f',
                    fontSize: '8px',
                    fontWeight: '600'
                }}
            >
                Pompiers
            </Box>

            <Spacer height="1" />

            <Box
                css={{
                    color: '#336998',
                    fontSize: '10px',
                    fontWeight: '500'
                }}
            >
                118
            </Box>

            <Spacer height="4" />

            <Box
                css={{
                    color: '#5a9e9f',
                    fontSize: '8px',
                    fontWeight: '600'
                }}
            >
                Ambulance
            </Box>

            <Spacer height="1" />

            <Box
                css={{
                    color: '#336998',
                    fontSize: '10px',
                    fontWeight: '500'
                }}
            >
                144
            </Box>
        </Flex>
    );
};
