import { Item } from 'common-front/src/designSystem/components/topMenu/item';
import { TopMenu } from 'common-front/src/designSystem/components/topMenu/topMenu';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Feature } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import { useOrganizationContext } from '../organization/organizationContext';

export const CommunityMenu = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { hasFeature, updateLocalOrganization } = useOrganizationContext();

    return (
        <TopMenu aria-label={translate('menu_communauta_47274')}>
            <Item path={CommunityPaths.COMMUNITY_DASHBOARD(organizationId)}>
                {translate('tableau_de_bord_24627')}
            </Item>

            <Item
                path={CommunityPaths.COMMUNITY_USERS(organizationId)}
                isActive={(pathname: string) =>
                    pathname.endsWith('/users') || pathname.match(/\/users\/\d+/) !== null
                }
                onClick={() => updateLocalOrganization({ usersSegmentId: null })}
            >
                {translate('vos_membres_38104')}
            </Item>

            <Item
                path={HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)}
                isActive={(pathname: string) =>
                    pathname.endsWith('/campaigns') ||
                    pathname.match(/\/community\/campaigns\/\d+/) !== null ||
                    pathname.match(/\/community\/campaign\/\d+/) !== null
                }
                onClick={() => updateLocalOrganization({ campaignsSegmentId: null })}
            >
                {translate('campagnes_31372')}
            </Item>

            <Item path={HeaventPaths.COMMUNITY_FORMS(organizationId)}>
                {translate('Form_plural')}
            </Item>

            {hasFeature(Feature.Delegation) && (
                <Item
                    path={HeaventPaths.COMMUNITY_DELEGATIONS(organizationId)}
                    isActive={(pathname: string) =>
                        pathname.endsWith('/delegations') ||
                        pathname.match(/\/community\/delegations\/\d+/) !== null ||
                        pathname.match(/\/community\/delegation\/\d+/) !== null
                    }
                >
                    {translate('d_l_gations_78318')}
                </Item>
            )}
        </TopMenu>
    );
};
