import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Fields } from '../../components/form/fields';
import { IFormInputProps, IInputProps } from '../components/input/commonInputProps';
import { TextInput as TextInputComponent } from '../components/textInput';

interface IAddressInputComponentProps extends Omit<IFormInputProps<IInputProps>, 'name'> {
    formattedAddressProps: FieldRenderProps<string, HTMLInputElement>;
    latitudeProps?: FieldRenderProps<number, HTMLInputElement>;
    longitudeProps?: FieldRenderProps<number, HTMLInputElement>;
    placeProps?: FieldRenderProps<google.maps.places.PlaceResult, HTMLInputElement>;
}

const AddressInputComponent = ({
    formattedAddressProps,
    latitudeProps,
    longitudeProps,
    placeProps,
    ...rest
}: IAddressInputComponentProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current!, {
            types: ['geocode']
        });

        autocomplete.addListener('place_changed', async () => {
            const place = autocomplete.getPlace();
            const lat = place.geometry!.location!.lat();
            const lng = place.geometry!.location!.lng();

            formattedAddressProps.input.onChange(place.formatted_address);
            latitudeProps?.input.onChange(lat);
            longitudeProps?.input.onChange(lng);
            placeProps?.input.onChange(place);
        });
    }, []);

    return (
        <TextInputComponent
            ref={inputRef}
            value={formattedAddressProps.input.value}
            onBlur={formattedAddressProps.input.onBlur}
            onChange={formattedAddressProps.input.onChange}
            onFocus={formattedAddressProps.input.onFocus}
            {...rest}
        />
    );
};

interface IAddressInputProps extends Omit<IFormInputProps<IInputProps>, 'name'> {
    addressName: string;
    latitudeName?: string;
    longitudeName?: string;
    placeName?: string;
}

export const AddressInput = ({
    addressName,
    latitudeName,
    longitudeName,
    placeName,
    ...rest
}: IAddressInputProps) => {
    const fieldsNames = [addressName];

    if (latitudeName) {
        fieldsNames.push(latitudeName);
    }

    if (longitudeName) {
        fieldsNames.push(longitudeName);
    }

    if (placeName) {
        fieldsNames.push(placeName);
    }

    return (
        <Fields
            names={fieldsNames}
            render={(fields) => (
                <AddressInputComponent
                    formattedAddressProps={fields[addressName]}
                    latitudeProps={latitudeName ? fields[latitudeName] : undefined}
                    longitudeProps={longitudeName ? fields[longitudeName] : undefined}
                    placeProps={placeName ? fields[placeName] : undefined}
                    {...rest}
                />
            )}
        />
    );
};
