import { Emptyable } from '../emptyable';
import {
    DelegationIdPathParam,
    EventIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from '../pathsTypes';
import { buildPath, PathComponents as CommonPathComponents } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventsPaths } from './eventsPaths';

const PathComponents = {
    ITEMS: {
        DELEGATIONS: {
            DELEGATION: 'delegation',
            DELEGATIONS: 'delegations'
        }
    },
    SECTIONS: {
        ACCREDITATIONS: 'accreditations',
        DASHBOARD: 'dashboard',
        DOCUMENTS: 'documents',
        MEMBER: 'member',
        MEMBERS: 'members'
    }
};

const ItemPaths = {
    DELEGATIONS: PathComponents.ITEMS.DELEGATIONS.DELEGATIONS,
    DELEGATION: (delegationId: DelegationIdPathParam) =>
        buildPath([PathComponents.ITEMS.DELEGATIONS.DELEGATION, delegationId])
};

export type DelegationParams = {
    organizationId: OrganizationIdPathParam;
    eventId: Emptyable<EventIdPathParam>;
    delegationId: DelegationIdPathParam;
};

export type DelegationUserParams = DelegationParams & {
    userInfoId: UserInfoIdPathParam;
};

export const DelegationsPaths = {
    // Access Paths
    DELEGATION: ({ organizationId, eventId, delegationId }: DelegationParams) =>
        buildPath([
            eventId
                ? EventsPaths.EVENT(organizationId, eventId)
                : CommunityPaths.COMMUNITY(organizationId),
            ItemPaths.DELEGATION(delegationId)
        ]),
    DELEGATION_DASHBOARD: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), PathComponents.SECTIONS.DASHBOARD]),
    DELEGATION_MEMBERS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), PathComponents.SECTIONS.MEMBERS]),
    DELEGATION_MEMBER: ({
        organizationId,
        eventId,
        delegationId,
        userInfoId
    }: DelegationUserParams) =>
        buildPath([
            DelegationsPaths.DELEGATION_MEMBERS({ organizationId, eventId, delegationId }),
            PathComponents.SECTIONS.MEMBER,
            userInfoId
        ]),
    DELEGATION_MEMBER_INFORMATION: (params: DelegationUserParams) =>
        buildPath([
            DelegationsPaths.DELEGATION_MEMBER(params),
            CommonPathComponents.SECTIONS.INFORMATION
        ]),
    DELEGATION_ACCREDITATIONS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), PathComponents.SECTIONS.ACCREDITATIONS]),
    DELEGATION_DOCUMENTS: (params: DelegationParams) =>
        buildPath([DelegationsPaths.DELEGATION(params), PathComponents.SECTIONS.DOCUMENTS])
};
