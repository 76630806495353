import { Select } from 'common-front/src/designSystem/components/select/select';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { LeaderInfosFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface ILeaderProps {
    leader: LeaderInfosFragment;

    onCanEditChange(canEdit: boolean): void;
    onDelete(): void;
}

export const Leader = (props: ILeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex align="center" gap="5">
            <AvatarNameEmail
                userInfo={props.leader.userInfo}
                hasName={isNonEmptyString(props.leader.userInfo.name)}
            />

            <Box width={150}>
                <Select
                    value={props.leader.canEdit}
                    onChange={(canEdit: boolean) => {
                        props.onCanEditChange(canEdit);
                    }}
                    shouldParseAsBoolean={true}
                >
                    <option value="true">{translate('_diteur_26621')}</option>
                    <option value="false">{translate('lecteur_24228')}</option>
                </Select>
            </Box>

            <Box color="gray500" css={{ cursor: 'pointer' }} onClick={props.onDelete}>
                <I icon="trash-can" />
            </Box>
        </Flex>
    );
};
