import { AccreditationCategory } from 'common-front/src/components/accreditations/v2/accreditationCategory';
import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DelegationAccreditation } from './delegationAccreditation';

interface IDelegationAccreditationCategoryProps {
    accreditations: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'];
    category: EventAccreditationsFragment['accreditationsCategories'][0];
    slots: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0]['slots'];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const DelegationAccreditationCategory = (props: IDelegationAccreditationCategoryProps) => {
    const accreditations = React.useMemo(
        () =>
            sortBy(
                props.accreditations.filter((a) => a.accreditationCategoryId === props.category.id),
                (a) => a.name.toLowerCase()
            ),
        [props.accreditations, props.category]
    );

    return (
        <AccreditationCategory category={props.category}>
            {accreditations.map((accreditation) => (
                <DelegationAccreditation
                    key={accreditation.id}
                    accreditation={accreditation}
                    slots={props.slots}
                    values={props.values}
                    change={props.change}
                />
            ))}
        </AccreditationCategory>
    );
};
