import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Span } from '../../../designSystem/components/span';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment, EventId } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (eventId: EventId) => {
    if (eventId === 4183) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/accreditation_vip.jpg';
    } else if (eventId === 3694) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/intervenant.jpg';
    } else {
        return '';
    }
};

interface IParis20KmContremarqueProps {
    event: DocumentUserCustomBadgeFragment;
}

export const Paris20KmContremarque = (props: IParis20KmContremarqueProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(props.event.id)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$avenir',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                css={{
                    fontSize: '24px',
                    fontWeight: '900',
                    left: '80px',
                    lineHeight: '28px',
                    position: 'absolute',
                    top: '261px'
                }}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#d8541d',
                    fontSize: '20px',
                    fontWeight: '800',
                    left: '80px',
                    position: 'absolute',
                    top: '325px'
                }}
            >
                <Span css={{ color: 'black', fontWeight: '400' }}>
                    {props.event.id === 4183 ? 'VIP' : 'Intervenant'} |
                </Span>{' '}
                {ui.fields.cf21104}
            </Box>

            <Box
                height={90}
                width={90}
                css={{
                    position: 'absolute',
                    right: '80px',
                    top: '261px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=90&width=90`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
