import { ContainerModule } from 'inversify';
import { AccreditationInputService } from './input/accreditationInput';
import { AccreditationsCustomFieldInputService } from './input/accreditationsCustomFieldInput';
import { AccreditationsSlotInputService } from './input/accreditationsSlotInput';
import { ArchiveInputService } from './input/archiveInput';
import { AuthInputService } from './input/authInput';
import { BackofficeOrganizationInputService } from './input/backofficeOrganizationInput';
import { CampaignInputService } from './input/campaignInput';
import { CommentInputService } from './input/commentInput';
import { CsvInputService } from './input/csvInput';
import { CustomFieldInputService } from './input/customFieldInput';
import { CustomSlotInputService } from './input/customSlotInput';
import { CustomSlotPeriodInputService } from './input/customSlotPeriodInput';
import { DelegationInputService } from './input/delegationInput';
import { DelegationsImportInputService } from './input/delegationsImportInput';
import { DesignInputService } from './input/designInput';
import { DocumentInputService } from './input/documentInput';
import { EmailSenderInputService } from './input/emailSenderInput';
import { EmailTemplateInputService } from './input/emailTemplateInput';
import { EventDuplicateInputService } from './input/eventDuplicateInput';
import { EventInputService } from './input/eventInput';
import { FieldInputService } from './input/fieldInput';
import { FilesImportInputService } from './input/filesImportInput';
import { ForgotPasswordInputService } from './input/forgotPasswordInput';
import { FormInputService } from './input/formInput';
import { FormParametersAccreditationsInputService } from './input/formParametersAccreditationsInput';
import { FormParametersAvailabilitiesInputService } from './input/formParametersAvailabilitiesInput';
import { FormParametersBroadcastInputService } from './input/formParametersBroadcastInput';
import { FormParametersGeneralInputService } from './input/formParametersGeneralInput';
import { FormParametersMissionsInputService } from './input/formParametersMissionsInput';
import { FormParametersStateInputService } from './input/formParametersStateInput';
import { FormRegisterInputService } from './input/formRegisterInput';
import { MassAccreditInputService } from './input/massAccreditInput';
import { MassAddEventInputService } from './input/massAddEventInput';
import { MassAssignInputService } from './input/massAssignInput';
import { MassConditionsInputService } from './input/massConditionsInput';
import { MassPreAssignInputService } from './input/massPreAssignInput';
import { MassStateUpdateInputService } from './input/massStateUpdateInput';
import { OrganizationInputService } from './input/organizationInput';
import { PhoneInputService } from './input/phoneInput';
import { PositionInputService } from './input/positionInput';
import { PositionsCustomFieldInputService } from './input/positionsCustomFieldInput';
import { PositionsSlotInputService } from './input/positionsSlotInput';
import { SegmentFolderInputService } from './input/segmentFolderInput';
import { SegmentInputService } from './input/segmentInput';
import { SignInCodeInputService } from './input/signInCodeInput';
import { SignUpInputService } from './input/signUpInput';
import { SkidataConnectionInfoInputService } from './input/skidataConnectionInfoInput';
import { TagInputService } from './input/tagInput';
import { TraceInputService } from './input/traceInput';
import { TranslationInputService } from './input/translationInput';
import { UserInfoInputService } from './input/userInfoInput';
import { UsersInfosMassEditInputService } from './input/usersInfosMassEditInputService';
import { VolunteerRegistrationInputService } from './input/volunteerRegistrationInput';
import { VolunteerRegistrationSlotInputService } from './input/volunteerRegistrationSlotInput';
import { VolunteersImportInputService } from './input/volunteersImportInput';
import { WeezeventInputService } from './input/weezeventInput';
import { WeezeventRuleInputService } from './input/weezeventRuleInput';
import { CountriesService } from './services/countriesService';
import { DateTimeService } from './services/dateTimeService';
import { DurationService } from './services/durationService';
import { IntervalService } from './services/intervalService';
import { NumberService } from './services/numberService';
import { TemplateService } from './services/templateService';
import { ValidateService } from './services/validateService';
import { FieldService } from './vo/field';
import { SegmentService } from './vo/segment';
import { AccreditationsSegmentsService } from './vo/segments/accreditationsSegmentsService';
import { CampaignsSegmentsService } from './vo/segments/campaignsSegmentsService';
import { CustomFieldsSegmentsService } from './vo/segments/customFieldsSegmentsService';
import { DelegationMembersSegmentsService } from './vo/segments/delegationMembersSegmentsService';
import { DelegationsSegmentsService } from './vo/segments/delegationsSegmentsService';
import { EventsSegmentsService } from './vo/segments/eventsSegmentsService';
import { OrganizationUsersSegmentsService } from './vo/segments/organizationUsersSegmentsService';
import { PositionsSegmentsService } from './vo/segments/positionsSegmentsService';
import { VolunteersSegmentsService } from './vo/segments/volunteersSegmentsService';

export const commonServicesContainerModule = new ContainerModule((bind) => {
    bind<CountriesService>(CountriesService).toSelf().inSingletonScope();
    bind<DateTimeService>(DateTimeService).toSelf().inSingletonScope();
    bind<DurationService>(DurationService).toSelf().inSingletonScope();
    bind<IntervalService>(IntervalService).toSelf().inSingletonScope();
    bind<NumberService>(NumberService).toSelf().inSingletonScope();
    bind<TemplateService>(TemplateService).toSelf().inSingletonScope();
    bind<ValidateService>(ValidateService).toSelf().inSingletonScope();
});

export const inputsContainerModule = new ContainerModule((bind) => {
    bind<AccreditationInputService>(AccreditationInputService).toSelf().inSingletonScope();
    bind<AccreditationsCustomFieldInputService>(AccreditationsCustomFieldInputService)
        .toSelf()
        .inSingletonScope();
    bind<AccreditationsSlotInputService>(AccreditationsSlotInputService)
        .toSelf()
        .inSingletonScope();
    bind<ArchiveInputService>(ArchiveInputService).toSelf().inSingletonScope();
    bind<AuthInputService>(AuthInputService).toSelf().inSingletonScope();
    bind<BackofficeOrganizationInputService>(BackofficeOrganizationInputService)
        .toSelf()
        .inSingletonScope();
    bind<CampaignInputService>(CampaignInputService).toSelf().inSingletonScope();
    bind<CommentInputService>(CommentInputService).toSelf().inSingletonScope();
    bind<CsvInputService>(CsvInputService).toSelf().inSingletonScope();
    bind<CustomFieldInputService>(CustomFieldInputService).toSelf().inSingletonScope();
    bind<CustomSlotInputService>(CustomSlotInputService).toSelf().inSingletonScope();
    bind<CustomSlotPeriodInputService>(CustomSlotPeriodInputService).toSelf().inSingletonScope();
    bind<DesignInputService>(DesignInputService).toSelf().inSingletonScope();
    bind<DelegationsImportInputService>(DelegationsImportInputService).toSelf().inSingletonScope();
    bind<DelegationInputService>(DelegationInputService).toSelf().inSingletonScope();
    bind<DocumentInputService>(DocumentInputService).toSelf().inSingletonScope();
    bind<EmailSenderInputService>(EmailSenderInputService).toSelf().inSingletonScope();
    bind<EmailTemplateInputService>(EmailTemplateInputService).toSelf().inSingletonScope();
    bind<EventDuplicateInputService>(EventDuplicateInputService).toSelf().inSingletonScope();
    bind<EventInputService>(EventInputService).toSelf().inSingletonScope();
    bind<FieldInputService>(FieldInputService).toSelf().inSingletonScope();
    bind<FilesImportInputService>(FilesImportInputService).toSelf().inSingletonScope();
    bind<ForgotPasswordInputService>(ForgotPasswordInputService).toSelf().inSingletonScope();
    bind<FormInputService>(FormInputService).toSelf().inSingletonScope();
    bind<FormParametersAccreditationsInputService>(FormParametersAccreditationsInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormParametersAvailabilitiesInputService>(FormParametersAvailabilitiesInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormParametersBroadcastInputService>(FormParametersBroadcastInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormParametersGeneralInputService>(FormParametersGeneralInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormParametersMissionsInputService>(FormParametersMissionsInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormParametersStateInputService>(FormParametersStateInputService)
        .toSelf()
        .inSingletonScope();
    bind<FormRegisterInputService>(FormRegisterInputService).toSelf().inSingletonScope();
    bind<MassAccreditInputService>(MassAccreditInputService).toSelf().inSingletonScope();
    bind<MassAddEventInputService>(MassAddEventInputService).toSelf().inSingletonScope();
    bind<MassAssignInputService>(MassAssignInputService).toSelf().inSingletonScope();
    bind<MassConditionsInputService>(MassConditionsInputService).toSelf().inSingletonScope();
    bind<MassPreAssignInputService>(MassPreAssignInputService).toSelf().inSingletonScope();
    bind<MassStateUpdateInputService>(MassStateUpdateInputService).toSelf().inSingletonScope();
    bind<OrganizationInputService>(OrganizationInputService).toSelf().inSingletonScope();
    bind<PhoneInputService>(PhoneInputService).toSelf().inSingletonScope();
    bind<PositionInputService>(PositionInputService).toSelf().inSingletonScope();
    bind<PositionsCustomFieldInputService>(PositionsCustomFieldInputService)
        .toSelf()
        .inSingletonScope();
    bind<PositionsSlotInputService>(PositionsSlotInputService).toSelf().inSingletonScope();
    bind<SegmentFolderInputService>(SegmentFolderInputService).toSelf().inSingletonScope();
    bind<SegmentInputService>(SegmentInputService).toSelf().inSingletonScope();
    bind<SignInCodeInputService>(SignInCodeInputService).toSelf().inSingletonScope();
    bind<SignUpInputService>(SignUpInputService).toSelf().inSingletonScope();
    bind<SkidataConnectionInfoInputService>(SkidataConnectionInfoInputService)
        .toSelf()
        .inSingletonScope();
    bind<TagInputService>(TagInputService).toSelf().inSingletonScope();
    bind<TraceInputService>(TraceInputService).toSelf().inSingletonScope();
    bind<TranslationInputService>(TranslationInputService).toSelf().inSingletonScope();
    bind<UserInfoInputService>(UserInfoInputService).toSelf().inSingletonScope();
    bind<UsersInfosMassEditInputService>(UsersInfosMassEditInputService)
        .toSelf()
        .inSingletonScope();
    bind<VolunteerRegistrationInputService>(VolunteerRegistrationInputService)
        .toSelf()
        .inSingletonScope();
    bind<VolunteerRegistrationSlotInputService>(VolunteerRegistrationSlotInputService)
        .toSelf()
        .inSingletonScope();
    bind<VolunteersImportInputService>(VolunteersImportInputService).toSelf().inSingletonScope();
    bind<WeezeventInputService>(WeezeventInputService).toSelf().inSingletonScope();
    bind<WeezeventRuleInputService>(WeezeventRuleInputService).toSelf().inSingletonScope();
});

export const vosContainerModule = new ContainerModule((bind) => {
    bind<FieldService>(FieldService).toSelf().inSingletonScope();
    bind<SegmentService>(SegmentService).toSelf().inSingletonScope();
    bind<AccreditationsSegmentsService>(AccreditationsSegmentsService).toSelf().inSingletonScope();
    bind<CampaignsSegmentsService>(CampaignsSegmentsService).toSelf().inSingletonScope();
    bind<CustomFieldsSegmentsService>(CustomFieldsSegmentsService).toSelf().inSingletonScope();
    bind<DelegationMembersSegmentsService>(DelegationMembersSegmentsService)
        .toSelf()
        .inSingletonScope();
    bind<DelegationsSegmentsService>(DelegationsSegmentsService).toSelf().inSingletonScope();
    bind<EventsSegmentsService>(EventsSegmentsService).toSelf().inSingletonScope();
    bind<OrganizationUsersSegmentsService>(OrganizationUsersSegmentsService)
        .toSelf()
        .inSingletonScope();
    bind<PositionsSegmentsService>(PositionsSegmentsService).toSelf().inSingletonScope();
    bind<VolunteersSegmentsService>(VolunteersSegmentsService).toSelf().inSingletonScope();
});
