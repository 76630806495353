import { AccreditationsCategories } from 'common-front/src/components/accreditations/v2/accreditationsCategories';
import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DelegationAccreditationCategory } from './delegationAccreditationCategory';

interface IDelegationAccreditationsCategoriesProps {
    accreditationCategories: EventAccreditationsFragment['accreditationsCategories'];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const DelegationAccreditationsCategories = (
    props: IDelegationAccreditationsCategoriesProps
) => {
    const selectedCategoriesIds = React.useMemo(
        () => props.values.accreditationsSlots.map((da) => da.accreditationCategoryId),
        [props.values]
    );
    const selectedAccreditationsIds = React.useMemo(
        () => props.values.accreditationsSlots.map((da) => da.accreditationId),
        [props.values]
    );
    const selectedSlotsIds = React.useMemo(
        () => props.values.accreditationsSlots.map((da) => da.accreditationSlotId),
        [props.values]
    );
    const categories = React.useMemo(
        () =>
            sortBy(
                props.accreditationCategories.filter((ac) => selectedCategoriesIds.includes(ac.id)),
                (c) => c.name.toLowerCase()
            ),
        [props.accreditationCategories, selectedCategoriesIds]
    );
    const accreditations = React.useMemo(
        () =>
            props.accreditationCategories
                .flatMap((ac) => ac.accreditations)
                .filter((a) => selectedAccreditationsIds.includes(a.id)),
        [props.accreditationCategories, selectedAccreditationsIds]
    );
    const slots = React.useMemo(
        () => accreditations.flatMap((a) => a.slots).filter((s) => selectedSlotsIds.includes(s.id)),
        [accreditations, selectedSlotsIds]
    );

    return (
        <AccreditationsCategories css={{ border: '1px solid $gray100', borderRadius: '$1' }}>
            {categories.map((category) => (
                <DelegationAccreditationCategory
                    key={category.id}
                    accreditations={accreditations}
                    category={category}
                    slots={slots}
                    values={props.values}
                    change={props.change}
                />
            ))}
        </AccreditationsCategories>
    );
};
