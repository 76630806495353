import { TextInput } from 'common-front/src/designSystem/components/textInput';
import {
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationUpdateCell';

interface ICommunityUserTextCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserTextCell = (props: ICommunityUserTextCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={valueString}
            organizationId={props.organizationId}
            userInfoId={props.userInfo.id}
            valueString={valueString}
            reload={props.reload}
        >
            {(value, setValue) => (
                <TextInput label={props.customField.name} value={value} onChange={setValue} />
            )}
        </UserInfoUpdateCell>
    );
};
