import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UpdateUserForm } from 'common-front/src/users/update/updateUserForm';
import {
    FormId,
    UpdateUserInfosEventQuery,
    UpdateUserInfosOrganizationQuery
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import {
    useUpdateUserInfosEventQuery,
    useUpdateUserInfosOrganizationQuery
} from '../../generated/graphqlHooks';

interface IUpdateUserLoaderProps {
    country:
        | UpdateUserInfosEventQuery['event']['country']
        | UpdateUserInfosOrganizationQuery['organization']['country'];
    data: UpdateUserInfosEventQuery | UpdateUserInfosOrganizationQuery;
    fallbackClosePath: string;
    forms:
        | UpdateUserInfosEventQuery['event']['forms']
        | UpdateUserInfosOrganizationQuery['organization']['forms'];

    getEditFormPath(formId: FormId): string;
}

const UpdateUserLoader = ({
    country,
    data,
    fallbackClosePath,
    forms,
    getEditFormPath
}: IUpdateUserLoaderProps) => {
    const {
        params: { organizationId, eventId, delegationId, userInfoId }
    } = useHeavent();

    const formIdToInsertedAt = React.useMemo(
        () =>
            Object.fromEntries(
                (data.organization?.userInfo.formsUsersInfos ?? []).map((fui) => [
                    fui.formId,
                    fui.insertedAt
                ])
            ),
        [data.organization]
    );
    const formIdToTeamCode = React.useMemo(
        () =>
            eventId
                ? Object.fromEntries(
                      (data.organization?.userInfo.formsUsersInfos ?? []).map((fui) => [
                          fui.formId,
                          fui.teamCode
                      ])
                  )
                : {},
        [data.organization]
    );

    return (
        <Switch>
            <Route
                path={[
                    HeaventPaths.EDIT_USER_FORM(
                        ':organizationId',
                        ':eventId',
                        ':userInfoId',
                        ':formId'
                    ),
                    HeaventPaths.EDIT_USER_DELEGATION_FORM(
                        ':organizationId',
                        ':eventId',
                        ':delegationId',
                        ':userInfoId',
                        ':formId'
                    ),
                    HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':formId'
                    ),
                    HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                        ':organizationId',
                        ':delegationId',
                        ':userInfoId',
                        ':formId'
                    )
                ]}
                children={
                    <UpdateUserForm
                        customFields={data.organization.customFields.nodes}
                        country={country}
                        fallbackClosePath={fallbackClosePath}
                        formIdToInsertedAt={formIdToInsertedAt}
                        formIdToTeamCode={formIdToTeamCode}
                        forms={forms}
                        showAllAndPrivate={true}
                        showIsFilled={true}
                        userInfo={data.organization.userInfo}
                        getEditUserFormPath={getEditFormPath}
                    />
                }
            />

            <Route
                children={
                    <Empty
                        path={
                            eventId
                                ? delegationId
                                    ? HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                          organizationId,
                                          eventId,
                                          delegationId,
                                          userInfoId,
                                          forms.length > 0 ? forms[0].id : 'all'
                                      )
                                    : HeaventPaths.EDIT_USER_FORM(
                                          organizationId,
                                          eventId,
                                          userInfoId,
                                          forms.length > 0 ? forms[0].id : 'all'
                                      )
                                : delegationId
                                ? HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                                      organizationId,
                                      delegationId,
                                      userInfoId,
                                      forms.length > 0 ? forms[0].id : 'all'
                                  )
                                : HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                                      organizationId,
                                      userInfoId,
                                      forms.length > 0 ? forms[0].id : 'all'
                                  )
                        }
                        replace={true}
                    />
                }
            />
        </Switch>
    );
};

export const UpdateUserLoaderEvent = () => {
    const {
        params: { organizationId, eventId, delegationId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUpdateUserInfosEventQuery({ organizationId, eventId, userInfoId });

    return (
        loader || (
            <UpdateUserLoader
                country={data.event.country}
                data={data}
                fallbackClosePath={
                    delegationId
                        ? HeaventPaths.DELEGATION(organizationId, eventId, delegationId)
                        : HeaventPaths.VOLUNTEERS(organizationId, eventId)
                }
                forms={
                    delegationId
                        ? data.event.forms.filter(
                              (form) =>
                                  isNonEmptyArray(form.delegationsIds) &&
                                  form.delegationsIds.includes(delegationId)
                          )
                        : data.event.forms
                }
                getEditFormPath={(formId) =>
                    delegationId
                        ? HeaventPaths.EDIT_USER_DELEGATION_FORM(
                              organizationId,
                              eventId,
                              delegationId,
                              userInfoId,
                              formId
                          )
                        : HeaventPaths.EDIT_USER_FORM(organizationId, eventId, userInfoId, formId)
                }
            />
        )
    );
};

export const UpdateUserLoaderOrganization = () => {
    const { organizationId, delegationId, userInfoId } = useParams();
    const { data, loader } = useUpdateUserInfosOrganizationQuery({ organizationId, userInfoId });

    return (
        loader || (
            <UpdateUserLoader
                country={data.organization.country}
                data={data}
                fallbackClosePath={
                    delegationId
                        ? HeaventPaths.COMMUNITY_DELEGATION(organizationId, delegationId)
                        : CommunityPaths.COMMUNITY_USERS(organizationId)
                }
                forms={
                    delegationId
                        ? data.organization.forms.filter(
                              (form) =>
                                  isNonEmptyArray(form.delegationsIds) &&
                                  form.delegationsIds.includes(delegationId)
                          )
                        : data.organization.forms
                }
                getEditFormPath={(formId) =>
                    delegationId
                        ? HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION_FORM(
                              organizationId,
                              delegationId,
                              userInfoId,
                              formId
                          )
                        : HeaventPaths.COMMUNITY_EDIT_USER_FORM(organizationId, userInfoId, formId)
                }
            />
        )
    );
};
