import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../util/isInvalid';
import { IFormInputProps } from '../components/input/commonInputProps';
import {
    IRichSelectProps,
    RichSelect as RichSelectComponent
} from '../components/richSelect/richSelect';

export const RichSelect = <T extends {}>({
    name,
    hint,
    state,
    multiple,
    ...rest
}: IFormInputProps<IRichSelectProps<T>>) => (
    <Field
        name={name}
        render={(fieldProps) => {
            const invalid = isInvalid(fieldProps.meta);

            return (
                <RichSelectComponent
                    hint={invalid ? fieldProps.meta.error : hint}
                    multiple={multiple}
                    name={name}
                    state={invalid ? 'error' : state}
                    values={
                        multiple
                            ? Array.isArray(fieldProps.input.value)
                                ? fieldProps.input.value
                                : []
                            : typeof fieldProps.input.value === 'number' ||
                              isNonEmptyString(fieldProps.input.value)
                            ? [fieldProps.input.value]
                            : []
                    }
                    onChange={(values) => {
                        if (multiple) {
                            fieldProps.input.onChange(values);
                        } else if (isNonEmptyArray(values)) {
                            fieldProps.input.onChange(values[0]);
                        } else {
                            fieldProps.input.onChange(null);
                        }
                    }}
                    {...rest}
                />
            );
        }}
    />
);
