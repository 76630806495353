import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Avatar } from 'common/src/components/avatar/avatar';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    FieldType,
    OrganizationId,
    SegmentCustomFieldFragment,
    SegmentId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { FieldService } from 'common/src/vo/field';
import { getFormInsertedAt, getFormsInsertedAt } from 'common/src/vo/formUserInfo';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditationsRightPanel } from '../../../../../common-front/src/components/accreditations/rightPanel/accreditationsRightPanel';
import { useEventContext } from '../../../events/show/eventContext';
import { VolunteerRegistrationAccreditations } from './cells/volunteerRegistrationAccreditations';
import { VolunteerRegistrationAccreditationStateCell } from './cells/volunteerRegistrationAccreditationStateCell';
import { VolunteerRegistrationAvailabilities } from './cells/volunteerRegistrationAvailabilities';
import { VolunteerRegistrationCheckboxCell } from './cells/volunteerRegistrationCheckboxCell';
import { VolunteerRegistrationDateCell } from './cells/volunteerRegistrationDateCell';
import { VolunteerRegistrationDelegation } from './cells/volunteerRegistrationDelegation';
import { VolunteerRegistrationForms } from './cells/volunteerRegistrationForms';
import { VolunteerRegistrationNumberCell } from './cells/volunteerRegistrationNumberCell';
import { VolunteerRegistrationPhoneCell } from './cells/volunteerRegistrationPhoneCell';
import { VolunteerRegistrationPositions } from './cells/volunteerRegistrationPositions';
import { VolunteerRegistrationSelectCell } from './cells/volunteerRegistrationSelectCell';
import { VolunteerRegistrationStateCell } from './cells/volunteerRegistrationStateCell';
import { VolunteerRegistrationTextareaCell } from './cells/volunteerRegistrationTextareaCell';
import { VolunteerRegistrationTextCell } from './cells/volunteerRegistrationTextCell';
import { VolunteerRegistrationWishedAccreditations } from './cells/volunteerRegistrationWishedAccreditations';
import { VolunteerRegistrationWishedPositions } from './cells/volunteerRegistrationWishedPositions';
import { AvailabilitiesRightPanel } from './rightPanels/availabilitiesRightPanel';
import { VolunteerRowDropdown } from './volunteerRowDropdown';

interface IVolunteerRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    segmentId: SegmentId;
    state: ICheckboxState;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
    reloadVolunteer(id: VolunteersRegistrationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const VolunteerRow = ({
    columns,
    customFields,
    eventId,
    organizationId,
    reload,
    reloadVolunteer,
    segmentId,
    state: checkBoxState,
    toggleRow,
    volunteerRegistration
}: IVolunteerRowProps) => {
    const { history, translate } = useHeavent();

    const { isEventAdmin } = useEventContext();
    const { setReferrerPath } = useReferrerContext();

    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const fieldService = useService(FieldService);

    const slugToCustomField = React.useMemo(
        () => new Map(customFields.map((cf) => [cf.slug, cf])),
        [customFields]
    );
    const formsInsertedAt = React.useMemo(
        () =>
            getFormsInsertedAt(
                dateTimeService,
                volunteerRegistration.userInfo.formsUsersInfos || []
            ),
        [volunteerRegistration]
    );

    const reloadVolunteerCallback = React.useCallback(() => {
        reloadVolunteer(volunteerRegistration.id);
    }, [volunteerRegistration, reloadVolunteer]);

    const userInfo = volunteerRegistration.userInfo;
    const state = volunteerRegistration.state;
    const accreditationState = volunteerRegistration.accreditationState;
    const accreditationPanelPath = HeaventPaths.VOLUNTEERS_LIST_USER_PANEL_ACCREDITATIONS(
        organizationId,
        eventId,
        segmentId,
        userInfo.id
    );
    const availabilitiesPanelPath = HeaventPaths.VOLUNTEERS_LIST_USER_PANEL_AVAILABILITIES(
        organizationId,
        eventId,
        segmentId,
        userInfo.id
    );

    return (
        <>
            <Row
                css={{
                    background: checkBoxState === 'checked' ? '$primary100' : 'white',
                    cursor: 'pointer',
                    '&:hover': {
                        background: checkBoxState === 'checked' ? '$primary100' : '$gray100'
                    }
                }}
                onMouseLeave={(e) => {
                    closeOtherDropdowns(e.target);
                }}
                onClick={() => {
                    history.push(
                        HeaventPaths.VOLUNTEERS_LIST_USER_INFORMATIONS(
                            organizationId,
                            eventId,
                            segmentId,
                            volunteerRegistration.userInfo.id
                        )
                    );
                }}
            >
                {isEventAdmin && (
                    <Cell justify="center" width={48}>
                        <Checkbox
                            state={checkBoxState}
                            onClick={(newState, e) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();

                                toggleRow(`ui${userInfo.id}`, newState);
                            }}
                        />
                    </Cell>
                )}

                {columns.map((field) => {
                    const customField = slugToCustomField.get(field.slug);

                    switch (field.slug) {
                        case VolunteerDefaultColumns.Accreditations:
                            return (
                                <VolunteerRegistrationAccreditations
                                    key={field.slug}
                                    eventId={eventId}
                                    onClick={() => {
                                        history.push(`${accreditationPanelPath}/assigned`);
                                    }}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.AccreditationState:
                            return (
                                <VolunteerRegistrationAccreditationStateCell
                                    key={field.slug}
                                    eventId={eventId}
                                    field={field}
                                    state={accreditationState}
                                    volunteerRegistrationId={volunteerRegistration.id}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.Availability:
                            return (
                                <VolunteerRegistrationAvailabilities
                                    key={field.slug}
                                    volunteerRegistration={volunteerRegistration}
                                    onClick={() => {
                                        history.push(availabilitiesPanelPath);
                                    }}
                                />
                            );
                        case VolunteerDefaultColumns.Comments:
                            return (
                                <Cell key={field.slug}>
                                    {isNonEmptyArray(userInfo.comments)
                                        ? userInfo.comments.flatMap((c) => c.content).join('; ')
                                        : ''}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.CompletedForms:
                            return (
                                <VolunteerRegistrationForms
                                    key={field.slug}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.FormsInsertedAt:
                            return (
                                <Cell key={field.slug}>
                                    <Box title={formsInsertedAt} css={{ ellipsis: true }}>
                                        {formsInsertedAt}
                                    </Box>
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Delegations:
                            return (
                                <VolunteerRegistrationDelegation
                                    key={field.slug}
                                    eventId={eventId}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.Email:
                            return <Cell key={field.slug}>{userInfo.email}</Cell>;
                        case VolunteerDefaultColumns.Id:
                            return (
                                <Cell key={field.slug} width={100}>
                                    {userInfo.id}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.InsertedAt:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {dateTimeService.toLocaleString(
                                        volunteerRegistration.insertedAt.toLocal(),
                                        LocaleFormats.DateTime
                                    )}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Name:
                            return (
                                <Cell key={field.slug}>
                                    <AvatarNameEmail userInfo={userInfo} hasName={true} size={32} />
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAccreditations:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {volunteerRegistration.numberOfAccreditations}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAssignments:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {volunteerRegistration.numberOfAssignments}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAssignmentsDays:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {volunteerRegistration.numberOfAssignmentsDays}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAvailableDays:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {volunteerRegistration.numberOfAvailableDays}
                                </Cell>
                            );
                        case 'picture':
                            return (
                                <Cell key={field.slug} width={80}>
                                    <Avatar
                                        size={32}
                                        image={userInfo.picture?.url}
                                        email={userInfo.email}
                                        name={userInfo.name}
                                    />
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Positions:
                            return (
                                <VolunteerRegistrationPositions
                                    key={field.slug}
                                    eventId={eventId}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.PositionsCategories:
                            return (
                                <Cell key={field.slug}>
                                    {uniq(
                                        volunteerRegistration?.positionsSlotsUsersInfos?.map(
                                            (psui) => psui.positionCategory.name
                                        )
                                    ).join(', ')}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.TeamCode:
                            return (
                                <Cell
                                    key={field.slug}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                    }}
                                >
                                    {volunteerRegistration.teamCode}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.VolunteerMinutes:
                            return (
                                <Cell key={field.slug} width={230}>
                                    {durationService.minutesToHoursMinutesString(
                                        volunteerRegistration.volunteerMinutes
                                    )}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.VolunteerRegistrationState:
                            return (
                                <VolunteerRegistrationStateCell
                                    key={field.slug}
                                    eventId={eventId}
                                    field={field}
                                    state={state}
                                    volunteerRegistrationId={volunteerRegistration.id}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.WishedAccreditationSlots:
                            return (
                                <VolunteerRegistrationWishedAccreditations
                                    key={field.slug}
                                    onClick={() => {
                                        history.push(`${accreditationPanelPath}/wished`);
                                    }}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.WishedPositions:
                            return (
                                <VolunteerRegistrationWishedPositions
                                    key={field.slug}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.WeezeventBarcodeId:
                            return (
                                <Cell key={field.slug}>
                                    {volunteerRegistration.weezeventParticipantInfo?.idBarcode ??
                                        ''}
                                </Cell>
                            );
                        default:
                            if (
                                Object.values<string>(VolunteerDefaultColumns).includes(field.slug)
                            ) {
                                console.error(`003: Unhandled default column ${field.slug}`);
                                return <Cell>ERR</Cell>;
                            }

                            return field.slug.startsWith('form_inserted_at') ? (
                                <Cell key={field.slug} width={230}>
                                    {getFormInsertedAt(
                                        dateTimeService,
                                        field.slug,
                                        volunteerRegistration.userInfo.formsUsersInfos || []
                                    )}
                                </Cell>
                            ) : customField?.fieldType === FieldType.Checkbox ? (
                                <VolunteerRegistrationCheckboxCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Date ? (
                                <VolunteerRegistrationDateCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Phone ? (
                                <VolunteerRegistrationPhoneCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Select ||
                              customField?.fieldType === FieldType.Country ||
                              customField?.fieldType === FieldType.Language ||
                              customField?.fieldType === FieldType.Sex ||
                              customField?.fieldType === FieldType.Nationality ? (
                                <VolunteerRegistrationSelectCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Text ? (
                                <VolunteerRegistrationTextCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Textarea ? (
                                <VolunteerRegistrationTextareaCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Number ? (
                                <VolunteerRegistrationNumberCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : (
                                <Cell key={field.slug}>
                                    {customField
                                        ? fieldService.getValueString(customField, userInfo, {
                                              fileReturnValue: 'name'
                                          })
                                        : ''}
                                </Cell>
                            );
                    }
                })}

                {isEventAdmin && (
                    <CellControls justify="center">
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        onClick={() => {
                                            setReferrerPath();
                                            history.push(
                                                HeaventPaths.EDIT_USER(
                                                    organizationId,
                                                    eventId,
                                                    volunteerRegistration.userInfo.id
                                                )
                                            );
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>

                        <VolunteerRowDropdown
                            eventId={eventId}
                            organizationId={organizationId}
                            reload={reload}
                            volunteerRegistration={volunteerRegistration}
                        />
                    </CellControls>
                )}
            </Row>

            <Switch>
                <Route path={accreditationPanelPath}>
                    <AccreditationsRightPanel
                        basePath={accreditationPanelPath}
                        eventId={eventId}
                        showActions={true}
                        volunteerRegistration={volunteerRegistration}
                        userInfoId={volunteerRegistration.userInfo.id}
                        onClose={() => {
                            history.push(
                                HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)
                            );

                            reloadVolunteerCallback();
                        }}
                    />
                </Route>

                <Route path={availabilitiesPanelPath}>
                    <AvailabilitiesRightPanel
                        eventId={eventId}
                        organizationId={organizationId}
                        userInfoId={volunteerRegistration.userInfo.id}
                        onClose={() => {
                            history.push(
                                HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, segmentId)
                            );
                        }}
                    />
                </Route>
            </Switch>
        </>
    );
};
