import { PhoneInput } from 'common-front/src/designSystem/components/phoneInput';
import {
    OrganizationId,
    PhoneInput as PhoneInputVO,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { PhoneInputService } from 'common/src/input/phoneInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationUpdateCell';

interface ICommunityUserPhoneCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserPhoneCell = (props: ICommunityUserPhoneCellProps) => {
    const fieldService = useService(FieldService);
    const phoneInput = useService(PhoneInputService);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<PhoneInputVO>(props.customField, props.userInfo);
        const defaultValues = phoneInput.basePhoneInputDefault();

        return {
            country: value?.country || defaultValues.country,
            code: value?.code || defaultValues.code,
            number: value?.number || defaultValues.number
        };
    }, []);

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={initialValue}
            organizationId={props.organizationId}
            userInfoId={props.userInfo.id}
            valueString={valueString}
            reload={props.reload}
        >
            {(value, setValue) => (
                <PhoneInput label={props.customField.name} value={value} onChange={setValue} />
            )}
        </UserInfoUpdateCell>
    );
};
