import { DateTime, Interval, LocaleOptions } from 'luxon';
import { VolunteersRegistrationsSlotInput } from '../generated/types';
import { isNonEmptyString } from './string';

export const DATE_ONLY_FORMAT = 'yyyy-MM-dd';
export const FORM_FORMAT = DATE_ONLY_FORMAT;
export const DATE_TIME_FORM_FORMAT = `yyyy-MM-dd'T'HH:mm`;
export const TIME_FORMAT = 'HH:mm';
export const UNSUPPORTED_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_EXPORT = 'dd-MM-yyyy HH:mm';

export function parseInterval(value: string): Interval {
    const [startDateStr, endDateStr] = JSON.parse(value);

    return Interval.fromDateTimes(
        DateTime.fromISO(startDateStr, { zone: 'UTC' }),
        DateTime.fromISO(endDateStr, { zone: 'UTC' })
    );
}

export function formatForForm(date?: DateTime): string {
    if (date && DateTime.isDateTime(date) && date.isValid) {
        return date.toFormat(FORM_FORMAT);
    } else {
        return '';
    }
}

export function formatForDateTimeForm(date?: DateTime, time?: string): string {
    if (date && DateTime.isDateTime(date) && date.isValid && isNonEmptyString(time)) {
        return `${date.toFormat(FORM_FORMAT)}T${time}`;
    } else {
        return '';
    }
}

export function parseFromForm(date?: string, format?: string): DateTime {
    if (date === undefined || date === null) {
        return DateTime.invalid('Invalid');
    } else {
        return DateTime.fromFormat(date, format || FORM_FORMAT, { zone: 'UTC' });
    }
}

export function parseFromDateTimeForm(date?: string): DateTime {
    if (isNonEmptyString(date)) {
        return DateTime.fromFormat(date, DATE_TIME_FORM_FORMAT, { zone: 'UTC' });
    } else {
        return DateTime.invalid('Invalid');
    }
}

export function toInterval({
    startDate,
    startTime,
    endDate,
    endTime
}: {
    startDate: DateTime;
    startTime: string;
    endDate: DateTime;
    endTime: string;
}): Interval {
    try {
        const [startTimeHours, startTimeMinutes] = startTime.split(':');
        const [endTimeHours, endTimeMinutes] = endTime.split(':');

        return Interval.fromDateTimes(
            startDate.set({
                hour: parseInt(startTimeHours, 10),
                minute: parseInt(startTimeMinutes, 10)
            }),
            endDate.set({
                hour: parseInt(endTimeHours, 10),
                minute: parseInt(endTimeMinutes, 10)
            })
        );
    } catch {
        return Interval.invalid('Invalid');
    }
}

export function fromRange(range: Interval | VolunteersRegistrationsSlotInput): Interval {
    if (Interval.isInterval(range)) {
        return range;
    } else {
        return toInterval(range);
    }
}

export function fromInterval(interval: Interval): {
    startDate: DateTime;
    startTime: string;
    endDate: DateTime;
    endTime: string;
} {
    return {
        startDate: interval.start!.set({ hour: 0, minute: 0, second: 0 }),
        startTime: interval.start!.toFormat('HH:mm:ss'),
        endDate: interval.end!.set({ hour: 0, minute: 0, second: 0 }),
        endTime: interval.end!.toFormat('HH:mm:ss')
    };
}

export type LocaleFormatType = LocaleOptions & Intl.DateTimeFormatOptions;

interface ILocaleFormats {
    DateOnly: {
        Numeric: LocaleFormatType;
        MonthLong: LocaleFormatType;
        MonthShort: LocaleFormatType;
        WeekdayLongMonthLong: LocaleFormatType;
        WeekdayShortMonthLong: LocaleFormatType;
        WeekdayShortMonthShort: LocaleFormatType;
        MonthLongYear: LocaleFormatType;
    };
    TimeOnly: LocaleFormatType;
    DateTime: LocaleFormatType;
    ShortDateTime: LocaleFormatType;
}

export const LocaleFormats: ILocaleFormats = {
    DateOnly: {
        Numeric: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        MonthLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        MonthShort: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        WeekdayLongMonthLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long'
        },
        WeekdayShortMonthLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'short'
        },
        WeekdayShortMonthShort: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        },
        MonthLongYear: {
            year: 'numeric',
            month: 'long'
        }
    },
    TimeOnly: {
        hour: '2-digit',
        minute: '2-digit'
    },
    DateTime: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    },
    ShortDateTime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }
};
