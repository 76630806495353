import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { FormAccreditations } from 'common-front/src/forms/accreditations/formAccreditations';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserUpdateLayout } from 'common-front/src/users/update/userUpdateLayout';
import { IUserUpdateAccreditationsValues } from 'common-front/src/users/updateAccreditations/userUpdateAccreditationsValues';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    RegisterPositionFilter,
    RegisterSlotDisplay,
    UserUpdateAccreditationsInfosQuery
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IUserUpdateAccreditationsProps {
    event: UserUpdateAccreditationsInfosQuery['event'];
    forms: UserUpdateAccreditationsInfosQuery['event']['forms'];
    isLoading: boolean;
    userInfo: UserUpdateAccreditationsInfosQuery['organization']['userInfo'];
    values: IUserUpdateAccreditationsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const UserUpdateAccreditations = (props: IUserUpdateAccreditationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const delegation = props.userInfo.volunteerRegistration?.delegation;
    const { getReferrerPath } = useReferrerContext();

    return (
        <UserUpdateLayout
            forms={props.forms}
            isLoading={props.isLoading}
            title={translate('mise_jour_des_60902', props.userInfo.nameOrEmail)}
            getTabPath={(formId) =>
                HeaventPaths.EDIT_USER_ACCREDITATIONS_FORM(
                    organizationId,
                    eventId,
                    userInfoId,
                    formId
                )
            }
            onCancel={() => {
                history.push(getReferrerPath() ?? HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            onUpdate={props.handleSubmit}
        >
            {props.forms.map((form, index) => (
                <TabPanel
                    key={form.id}
                    path={HeaventPaths.EDIT_USER_ACCREDITATIONS_FORM(
                        organizationId,
                        eventId,
                        userInfoId,
                        form.id
                    )}
                >
                    <Spacer height="7" />

                    <FormAccreditations
                        delegationAccreditations={
                            delegation?.formId === form.id ? delegation.accreditationsSlots : []
                        }
                        event={props.event}
                        options={{
                            ...form,
                            showFullAccreditation: true,
                            slotDisplay: RegisterSlotDisplay.Hide,
                            accreditationFilter: RegisterPositionFilter.None
                        }}
                        accreditationsSlotsIds={
                            props.values.userInfo.accreditations[index].accreditationsSlotsIds
                        }
                        prefix={`userInfo.accreditations[${index}].`}
                        slots={[]}
                        userInfosFields={props.userInfo.fields}
                        change={props.change}
                    />
                </TabPanel>
            ))}
        </UserUpdateLayout>
    );
};
