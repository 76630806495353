import { injectable } from 'inversify';
import { SupportedLanguage } from '../generated/types';

export type TranslateFn = (key: string, ...args: any[]) => string;

@injectable()
export class TranslationService {
    constructor(
        public language: SupportedLanguage,
        private translations: { [key: string]: string }
    ) {}

    translate(key: string, ...args: any[]): string {
        if (process.env.NODE_ENV === 'test' && !process.env.JEST_WORKER_ID) {
            return `${key} - ${JSON.stringify(args)}`;
        } else {
            let translation = this.translations[key] || key;
            const pluralTranslation = this.translations[`${key}_plural`];

            if (pluralTranslation && typeof args[0] === 'number' && args[0] > 1) {
                translation = pluralTranslation;
            }

            if (Array.isArray(args) && args.length > 0) {
                translation = args.reduce((currentTranslation, arg, index) => currentTranslation.replaceAll(`{${index + 1}}`, arg), translation);
            }

            return translation;
        }
    }

    formatListValues(values: string[], type: Intl.ListFormatType = 'disjunction'): string {
        return new Intl.ListFormat(this.language.toLowerCase(), {
            style: 'long',
            type
        }).format(values);
    }
}
