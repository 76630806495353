import { Button } from 'common-front/src/designSystem/components/button';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    CreatePositionCustomFieldsFragment,
    CustomFieldId,
    FieldType
} from 'common/src/generated/types';
import { ICreateUpdateAccreditationValues } from 'common/src/input/accreditationInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';

interface IConditionFormProps {
    customFields: CreatePositionCustomFieldsFragment['customFields'];
    customFieldValue: ICreateUpdateAccreditationValues['accreditation']['accreditationsCustomFields'][0];
    field: string;
    index: number;

    change(field: string, value: any): void;
    onRemove(): void;
}

export const ConditionForm = (props: IConditionFormProps) => {
    const translate = useTranslate();
    const selectedCustomField = props.customFields.nodes.find(
        (cf) => cf.id === props.customFieldValue.customFieldId
    );

    return (
        <>
            <OnChange name={`${props.field}.customFieldId`}>
                {(customFieldId: CustomFieldId) => {
                    const customField = props.customFields.nodes.find(
                        (cf) => cf.id === customFieldId
                    );

                    props.change(
                        `${props.field}.conditionValue`,
                        customField?.fieldType === FieldType.Select ? [] : ''
                    );
                }}
            </OnChange>

            <Flex
                direction="column"
                gap="4"
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$3 $4'
                }}
            >
                <Box font="gray800 textXs semiBold" css={{ textTransform: 'uppercase' }}>
                    {translate('condition_n_1_11603', props.index + 1)}
                </Box>

                <Flex align="center" gap="4">
                    <Box css={{ flex: '1' }}>
                        <RichSelect
                            isSearchVisible={true}
                            name={`${props.field}.customFieldId`}
                            label={translate('CustomField')}
                        >
                            <option value="-1">{translate('s_lectionnez_un_62064')}</option>

                            {props.customFields.nodes.map((customField) => (
                                    <option key={customField.id} value={customField.id}>
                                        {customField.name}
                                    </option>
                                ))}
                        </RichSelect>
                    </Box>

                    <Box color="gray500" fontSize="textMd" css={{ paddingTop: '$6' }}>
                        <I icon="arrow-right" />
                    </Box>

                    <Box css={{ flex: '1' }}>
                        {selectedCustomField?.fieldType === FieldType.Checkbox && (
                            <Select
                                name={`${props.field}.conditionValue`}
                                label={translate('accessible_seul_31434')}
                                shouldParseAsBoolean={true}
                            >
                                <option value="">{translate('s_lectionnez_un_44130')}</option>
                                <option value="true">{translate('true')}</option>
                                <option value="false">{translate('false')}</option>
                            </Select>
                        )}

                        {selectedCustomField?.fieldType === FieldType.Select && (
                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name={`${props.field}.conditionValue`}
                                label={translate('accessible_seul_31434')}
                            >
                                {selectedCustomField.values.map(({ id, value }) => (
                                        <option key={id} value={id}>
                                            {value}
                                        </option>
                                    ))}
                            </RichSelect>
                        )}
                    </Box>

                    <Box css={{ paddingTop: '$6' }}>
                        <Button
                            color="white"
                            size="sm"
                            leftIcon="trash-can"
                            onClick={props.onRemove}
                        />
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
