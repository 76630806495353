import { AccreditationsSlot, DelegationAccreditationSlot } from 'common/src/generated/types';
import * as React from 'react';
import {
    LimitSource,
    LimitSourceAccreditationIcon
} from '../../accreditAssign/accreditationLimitSource';

/*
 *  This function caculates which, if any, quanitity limit(s) apply to an
 *  accreditation. The logic is tricky, so here's a human explanation:
 *
 *  For all limits that may be set, display the icon(s) of:
 *  a) the limit that is closest to being hit; and
 *  b) any other limits that are equally close to being hit.
 *
 *  For the assigned and max resource numbers, return only those of
 *  the limit closest to being hit. In case b above, choose the slot
 *  numbers over the delegation numbers.
 */
export const getAccreditationLimits = (
    da:
        | Pick<
              DelegationAccreditationSlot,
              'assignedResources' | 'wishedResources' | 'maxResources'
          >
        | undefined,
    slot:
        | Pick<AccreditationsSlot, 'assignedResources' | 'wishedResources' | 'maxResources'>
        | undefined
) => {
    const slotAssignedResources = slot?.assignedResources ?? 0;
    const slotWishedResources = slot?.wishedResources ?? 0;
    const slotMaxResources = slot?.maxResources ?? Infinity;
    const daAssignedResources = da?.assignedResources ?? 0;
    const daWishedResources = da?.wishedResources ?? 0;
    const daMaxResources = da?.maxResources ?? Infinity;

    const isMaxedOutForDelegation = daAssignedResources >= daMaxResources;
    const isMaxedOutForSlot = slotAssignedResources >= slotMaxResources;
    const isMaxedOut = isMaxedOutForDelegation || isMaxedOutForSlot;

    const slotGap = slotMaxResources - slotAssignedResources;
    const slotWishedGap = slotMaxResources - slotWishedResources;
    const daGap = daMaxResources - daAssignedResources;
    const daWishedGap = daMaxResources - daWishedResources;

    const limitSource: string =
        slotGap === daGap
            ? LimitSource.Both
            : slotGap < daGap
            ? LimitSource.Slot
            : LimitSource.DelegationAccreditation;
    const limitSourceWished: string =
        slotWishedGap === daWishedGap
            ? LimitSource.Both
            : slotWishedGap < daWishedGap
            ? LimitSource.Slot
            : LimitSource.DelegationAccreditation;

    const limitIcon = <LimitSourceAccreditationIcon limitSource={limitSource} />;
    const limitIconWished = <LimitSourceAccreditationIcon limitSource={limitSourceWished} />;

    const maxResources =
        limitSource === LimitSource.DelegationAccreditation ? daMaxResources : slotMaxResources;
    const assignedResources = da ? daAssignedResources : slotAssignedResources;
    const wishedResources = da ? daWishedResources : slotWishedResources;

    const percent = maxResources !== Infinity ? assignedResources / maxResources : undefined;
    const percentWished = maxResources !== Infinity ? wishedResources / maxResources : undefined;

    return {
        assignedResources,
        wishedResources,
        isMaxedOut,
        limitIcon,
        limitIconWished,
        limitSource,
        limitSourceWished,
        maxResources,
        percent,
        percentWished
    };
};
