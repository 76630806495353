import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDelegationQuery } from '../../generated/graphqlHooks';
import { Delegation } from './delegation';

export const DelegationLoader = () => {
    const {
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { data, loader, reload } = useDelegationQuery({ organizationId, delegationId });

    return (
        loader || (
            <Switch>
                <Route
                    exact
                    path={DelegationsPaths.DELEGATION({
                        organizationId: ':organizationId',
                        eventId: eventId ? ':eventId' : undefined,
                        delegationId: ':delegationId'
                    })}
                    children={
                        <Empty
                            path={DelegationsPaths.DELEGATION_DASHBOARD({
                                organizationId,
                                eventId,
                                delegationId
                            })}
                            replace={true}
                        />
                    }
                />

                <Route children={<Delegation organization={data.organization} reload={reload} />} />
            </Switch>
        )
    );
};
