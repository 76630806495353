import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditationsSlotId,
    DelegationAccreditationInput,
    DelegationAccreditationSlotInput,
    EventAccreditationsFragment
} from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditationSlot';
import { uniqBy } from 'lodash-es';
import * as React from 'react';

export function addOrRemoveAccreditation(
    currentAccreditations: DelegationAccreditationInput[],
    newAccreditationsSlots: DelegationAccreditationSlotInput[],
    shouldAdd: boolean,
    addedAccreditationSlot: DelegationAccreditationSlotInput,
    change: (name: string, value: any) => void
) {
    let newAccreditations: DelegationAccreditationInput[];
    const accreditationsIds = newAccreditationsSlots.map((as) => as.accreditationId);

    if (shouldAdd) {
        const newAccreditation: DelegationAccreditationInput = {
            accreditationCategoryId: addedAccreditationSlot.accreditationCategoryId,
            accreditationId: addedAccreditationSlot.accreditationId,
            maxResources: null
        };

        newAccreditations = uniqBy(
            [...currentAccreditations, newAccreditation],
            (a) => a.accreditationId
        );
    } else {
        newAccreditations = currentAccreditations.filter((a) =>
            accreditationsIds.includes(a.accreditationId)
        );
    }

    change('delegation.accreditations', newAccreditations);
}

interface IAccreditationsSelectPanelAccreditationSlotProps {
    accreditationCategoryId: AccreditationsCategoryId;
    accreditationId: AccreditationId;
    accreditationName: string;
    accreditationSlotsIdsValues: AccreditationsSlotId[];
    slot: EventAccreditationsFragment['accreditationsCategories'][number]['accreditations'][number]['slots'][number];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const AccreditationsSelectPanelAccreditationSlot = ({
    accreditationCategoryId,
    accreditationId,
    accreditationName,
    accreditationSlotsIdsValues,
    change,
    slot,
    values
}: IAccreditationsSelectPanelAccreditationSlotProps) => {
    const dateTimeService = useService(DateTimeService);

    const isSelected = React.useMemo(
        () => accreditationSlotsIdsValues.includes(slot.id),
        [accreditationSlotsIdsValues]
    );

    const onChange = () => {
        const newAccreditationSlot = {
            accreditationCategoryId,
            accreditationId,
            accreditationSlotId: slot.id,
            isDefault: false,
            isVisible: true
        };
        const newAccreditationsSlots = isSelected
            ? values.accreditationsSlots.filter((da) => da.accreditationSlotId !== slot.id)
            : [...values.accreditationsSlots, newAccreditationSlot];

        change('delegation.accreditationsSlots', newAccreditationsSlots);
        addOrRemoveAccreditation(
            values.accreditations,
            newAccreditationsSlots,
            !isSelected,
            newAccreditationSlot,
            change
        );
    };

    return (
        <CheckboxText onClick={onChange} state={isSelected ? 'checked' : 'unchecked'}>
            {fullName(dateTimeService, slot, accreditationName)}
        </CheckboxText>
    );
};
