import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    DelegationId,
    DelegationsDelegationFragment,
    EventId,
    OrganizationId
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { PossibleColumn } from 'common/src/vo/segment';
import { DelegationDefaultColumns } from 'common/src/vo/segments/delegationsSegmentsService';
import * as React from 'react';
import { PositionCreatedByCell } from '../../positions/list/list/cells/positionCreatedByCell';
import { PositionRowResources } from '../../positions/list/list/positionRowResources';
import { DelegationsDeadlineDateCell } from './cells/delegationsDeadlineDateCell';
import { DelegationsFormCell } from './cells/delegationsFormCell';
import { DelegationsMaxResourcesCell } from './cells/delegationsMaxResourcesCell';
import { DelegationsNameCell } from './cells/delegationsNameCell';
import { DelegationsTagsCell } from './cells/delegationsTagsCell';
import { DelegationRowDropdown } from './delegationRowDropdown';
import { useDelegationsContext } from './delegationsContext';

interface IDelegationRowProps {
    columns: PossibleColumn[];
    delegation: DelegationsDelegationFragment;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    state: ICheckboxState;

    reload(): void;
    reloadDelegation(id: DelegationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const DelegationRow = ({
    columns,
    delegation,
    eventId,
    organizationId,
    state,
    reload,
    reloadDelegation: reloadDelegationProps,
    toggleRow
}: IDelegationRowProps) => {
    const { translate } = useHeavent();
    const { setReferrerPath } = useReferrerContext();
    const { getEditPath } = useDelegationsContext();
    const reloadDelegation = React.useCallback(() => {
        reloadDelegationProps(delegation.id);
    }, [delegation, reloadDelegationProps]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell justify="center" width={48}>
                <Checkbox
                    state={state}
                    onClick={(newState, e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();

                        toggleRow(`d${delegation.id}`, newState);
                    }}
                />
            </Cell>

            {columns.map((field) =>
                field.slug === DelegationDefaultColumns.Id ? (
                    <Cell key={field.slug} width={100}>
                        {delegation.id}
                    </Cell>
                ) : field.slug === DelegationDefaultColumns.Name ? (
                    <DelegationsNameCell
                        key={field.slug}
                        delegation={delegation}
                        reload={reloadDelegation}
                    />
                ) : field.slug === DelegationDefaultColumns.State ? (
                    <Cell key={field.slug}>
                        <Badge color="success">{translate(delegation.state)}</Badge>
                    </Cell>
                ) : field.slug === DelegationDefaultColumns.Leaders ? (
                    <Cell key={field.slug}>
                        {delegation.leaders.map((leader) => leader.name).join(', ')}
                    </Cell>
                ) : field.slug === DelegationDefaultColumns.Form ? (
                    <DelegationsFormCell
                        key={field.slug}
                        delegation={delegation}
                        reload={reloadDelegation}
                    />
                ) : field.slug === DelegationDefaultColumns.DeadlineDate ? (
                    <DelegationsDeadlineDateCell
                        key={field.slug}
                        delegation={delegation}
                        reload={reloadDelegation}
                    />
                ) : field.slug === DelegationDefaultColumns.MaxResources ? (
                    <DelegationsMaxResourcesCell
                        key={field.slug}
                        delegation={delegation}
                        reload={reloadDelegation}
                    />
                ) : field.slug === DelegationDefaultColumns.AssignedResources ? (
                    <Cell key={field.slug}>
                        <PositionRowResources
                            assignedResources={delegation.numberOfResources}
                            resources={delegation.maxResources}
                        />
                    </Cell>
                ) : field.slug === DelegationDefaultColumns.Tags ? (
                    <DelegationsTagsCell
                        key={field.slug}
                        delegation={delegation}
                        reload={reloadDelegation}
                    />
                ) : field.slug === DelegationDefaultColumns.FillingRate ? (
                    <Cell key={field.slug}>{delegation.fillingRate}%</Cell>
                ) : field.slug === DelegationDefaultColumns.CreatedBy ? (
                    <PositionCreatedByCell key={field.slug} createdBy={delegation.createdBy} />
                ) : field.slug === DelegationDefaultColumns.ShowInDelegation ? (
                    <Cell key={field.slug}>
                        {delegation.showInDelegationSpace
                            ? translate('oui_54361')
                            : translate('non_33516')}
                    </Cell>
                ) : (
                    <Cell />
                )
            )}
            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                onClick={setReferrerPath}
                                to={getEditPath(delegation.id)}
                            />
                        </Box>
                    </Trigger>
                </Tooltip>

                <DelegationRowDropdown
                    delegation={delegation}
                    organizationId={organizationId}
                    eventId={eventId}
                    reload={reload}
                />
            </CellControls>
        </Row>
    );
};
