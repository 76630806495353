import { Accreditation } from 'common-front/src/components/accreditations/v2/accreditation';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DelegationAccreditationSlot } from './delegationAccreditationSlot';

interface IDelegationAccreditationProps {
    accreditation: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0];
    slots: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0]['slots'];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const DelegationAccreditation = (props: IDelegationAccreditationProps) => {
    const { translate } = useHeavent();
    const index = React.useMemo(
        () =>
            props.values.accreditations.findIndex(
                (a) => a.accreditationId === props.accreditation.id
            ),
        [props.values, props.accreditation]
    );
    const slots = React.useMemo(() => {
        if (props.accreditation.hasSlots) {
            return sortBy(
                props.slots.filter((s) => s.accreditationId === props.accreditation.id),
                (s) => [s.date?.toMillis() ?? 1, s.name?.toLowerCase() || '']
            );
        } else {
            return [
                {
                    id: props.accreditation.hiddenSlotId,
                    accreditationId: props.accreditation.id,
                    name: ''
                }
            ];
        }
    }, [props.slots, props.accreditation]);
    const prefix = `delegation.accreditations[${index}]`;

    return (
        <Accreditation
            accreditation={props.accreditation}
            displaySlots={true}
            numberOfSlots={slots.length}
            renderActions={() =>
                props.accreditation.hasSlots ? (
                    <Flex
                        align="center"
                        gap="2"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <Box>{translate('limite_totale_35352')}</Box>

                        <Box width={60}>
                            <TextInput name={`${prefix}.maxResources`} />
                        </Box>
                    </Flex>
                ) : null
            }
        >
            <Table
                css={{
                    '& .table-row:last-child': { borderBottom: 'none' }
                }}
                hideBorder={true}
            >
                <HeaderRow>
                    <HeaderCell>{translate('date_du_cr_neau_82295')}</HeaderCell>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    <HeaderCell>{translate('accr_ditation_p_28030')}</HeaderCell>
                    <HeaderCell width={100}>{translate('visibilit_46792')}</HeaderCell>
                    <HeaderCell width={130}>{translate('total_07173')}</HeaderCell>
                </HeaderRow>

                {slots.map((slot) => (
                    <DelegationAccreditationSlot
                        key={slot.id}
                        accreditationDisplay={props.accreditation.accreditationDisplay}
                        slot={slot}
                        values={props.values}
                        change={props.change}
                    />
                ))}
            </Table>
        </Accreditation>
    );
};
