import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Link as _Link, LinkProps } from 'react-router-dom';
import { convertToPercentOrPx } from '../util/unit';

export interface ILinkProps extends LinkProps {
    children: React.ReactNode;
    height?: number | string;
}

export const Link = ({
    children,
    download,
    height,
    onClick,
    style,
    target,
    to,
    ...props
}: ILinkProps) => {
    // Catch any accidentally passed paths for other (sub)domains
    if (isNonEmptyString(to) && to.startsWith('http')) {
        to = { pathname: to };
    }

    const css: any = {};

    if (height) {
        css.height = convertToPercentOrPx(height);
    }

    return (
        <_Link
            download={download}
            onClick={onClick}
            style={{
                color: 'inherit',
                cursor: 'pointer',
                textDecoration: 'none',
                ...css,
                ...style
            }}
            target={target}
            to={to}
            {...props}
        >
            {children}
        </_Link>
    );
};
Link.displayName = 'LinkWrapper';
