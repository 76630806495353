import { Empty } from 'common-front/src/components/empty/empty';
import { useUserUpdateAccreditationsInfosQuery } from 'common-front/src/generated/graphqlHooks';
import { RegisterAccreditationDisplay } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UserUpdateAccreditationsForm } from './userUpdateAccreditationsForm';

export const UserUpdateAccreditationsLoader = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useUserUpdateAccreditationsInfosQuery({
        organizationId,
        eventId,
        userInfoId,
        loadDelegation: true
    });
    const forms = React.useMemo(() => {
        if (data.organization && data.event) {
            const vr = data.organization.userInfo.volunteerRegistration!;

            return data.event.forms.filter(
                (form) =>
                    form.accreditationDisplay !== RegisterAccreditationDisplay.None ||
                    (vr.delegation?.formId === form.id &&
                        isNonEmptyArray(vr.delegation.accreditationsSlots))
            );
        } else {
            return [];
        }
    }, [data.organization, data.event]);

    if (loader) {
        return loader;
    } else {
        return isNonEmptyArray(forms) ? (
            <Switch>
                <Route
                    path={HeaventPaths.EDIT_USER_ACCREDITATIONS_FORM(
                        ':organizationId',
                        ':eventId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UserUpdateAccreditationsForm
                            event={data.event}
                            forms={forms}
                            userInfo={data.organization.userInfo}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.EDIT_USER_ACCREDITATIONS_FORM(
                                organizationId,
                                eventId,
                                userInfoId,
                                forms[0].id
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        ) : (
            <Empty />
        );
    }
};
