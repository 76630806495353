import { Blank } from 'common-front/src/components/blank/blank';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Svgs } from 'common-front/src/util/assets';
import { AccreditationsRendering } from 'common-front/src/vo/accreditationsRendering';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AccreditationsSlotsList } from '../../accreditationsSlots/list/accreditationsSlotsList';
import { AccreditationsActions } from './accreditationsActions';
import { AccreditationsHeader } from './accreditationsHeader';
import { AccreditationsList } from './accreditationsList';

interface IAccreditationsContentProps {
    numberOfAccreditations: number;
}

export const AccreditationsContent = (props: IAccreditationsContentProps) => {
    const translate = useTranslate();
    const { limit, reloadKey, rendering, segment, setReloadKey } = useSegmentsContext();

    return (
        <>
            <AccreditationsHeader
                name={segment.name}
                reload={() => {
                    setReloadKey(reloadKey + 1);
                }}
            />

            {props.numberOfAccreditations === 0 ? (
                <Box css={{ flex: '1', overflow: 'hidden' }}>
                    <Blank
                        imageSrc={Svgs.AccreditationsBlank}
                        title={translate('vous_n_avez_pas_53408')}
                        subtitle=""
                    />
                </Box>
            ) : (
                <>
                    <Spacer height="7" />

                    <SegmentTableHeader
                        searchRow={
                            <SearchRow
                                actions={<AccreditationsActions />}
                                searchPlaceholder={translate('rechercher_une_65646')}
                                showEditColumns={true}
                                showEditMode={true}
                                showSearchColumns={true}
                            />
                        }
                        selectSegment={
                            <SelectSegment
                                pageSelectedText={translate('les_1_accr_di_60244', limit)}
                                segmentSelectedText={translate(
                                    'les_1_accr_di_20650',
                                    segment.count,
                                    segment.name
                                )}
                            />
                        }
                    />

                    {rendering === AccreditationsRendering.SlotsList ? (
                        <AccreditationsSlotsList />
                    ) : (
                        <AccreditationsList />
                    )}
                </>
            )}
        </>
    );
};
