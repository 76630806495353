import * as React from 'react';

export interface IReferrerContext {
    getReferrerPath(): string | null;
    unsetReferrerPath(): void;
    setReferrerPath(): void;
}

const ReferrerContext = React.createContext<IReferrerContext>({} as any);

interface IReferrerContextProviderProps {
    children: React.ReactNode;
}

export const ReferrerContextProvider = ({ children }: IReferrerContextProviderProps) => {
    const [referrerPath, setReferrerPath] = React.useState<string | null>(null);
    let shouldUnsetReferrerPath = false;

    const unsetReferrerPath = () => {
        setReferrerPath(null);
    };

    React.useEffect(() => {
        if (shouldUnsetReferrerPath) {
            unsetReferrerPath();
            shouldUnsetReferrerPath = false;
        }
    }, [shouldUnsetReferrerPath]);

    window.addEventListener('popstate', () => {
        if (location.pathname === referrerPath) {
            unsetReferrerPath();
        }
    });

    return (
        <ReferrerContext.Provider
            value={{
                getReferrerPath() {
                    const path = referrerPath;
                    shouldUnsetReferrerPath = true;
                    return path;
                },
                setReferrerPath() {
                    setReferrerPath(location.pathname);
                },
                unsetReferrerPath() {
                    unsetReferrerPath();
                }
            }}
        >
            {children}
        </ReferrerContext.Provider>
    );
};

export function useReferrerContext(): IReferrerContext {
    return React.useContext(ReferrerContext);
}
