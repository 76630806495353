import { FormColumns } from 'common-front/src/components/form/formColumns';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DelegationEventInfosQuery, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { CreateDelegationColumns } from './createDelegationColumns';

interface ICreateDelegationParametersProps {
    customFields: SegmentCustomFieldFragment[];
    delegationType: 'event' | 'organization';
    forms: DelegationEventInfosQuery['event']['forms'];
    initialValues: ICreateUpdateDelegationValues;
    values: ICreateUpdateDelegationValues;

    change(name: string, value: any): void;
}

export const CreateDelegationParameters = (props: ICreateDelegationParametersProps) => {
    const translate = useTranslate();
    const forms = React.useMemo(() => sortBy(props.forms, (f) => f.name), [props.forms]);
    const [isTimeLimited, setIsTimeLimited] = React.useState(
        !!props.initialValues.delegation.deadlineDate
    );
    const [isLimited, setIsLimited] = React.useState(!!props.initialValues.delegation.maxResources);

    return (
        <>
            <Flex direction="column">
                <FormColumns title={translate('Form')}>
                    <RichSelect
                        isSearchVisible={true}
                        label={translate('avec_quel_formu_60160')}
                        name="delegation.formId"
                        placeholder={translate('s_lectionnez_un_77066')}
                    >
                        {forms.map((form) => (
                            <option key={form.id} value={form.id}>
                                {form.name}
                            </option>
                        ))}
                    </RichSelect>
                </FormColumns>

                <HorizontalSpacerSeparator height="5" />

                <FormColumns title={translate('inscription_dan_16147')}>
                    <Box font="gray800 textSm semiBold">{translate('les_inscription_64653')}</Box>

                    <Spacer height="2" />

                    <RadioText
                        state={isTimeLimited ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setIsTimeLimited(true);
                        }}
                    >
                        {translate('oui_il_y_a_une_68479')}
                    </RadioText>

                    {isTimeLimited && (
                        <>
                            <Spacer height="2" />

                            <DateInput name="delegation.deadlineDate" />

                            <Spacer height="3" />
                        </>
                    )}

                    <Spacer height="3" />

                    <RadioText
                        state={isTimeLimited ? 'unchecked' : 'checked'}
                        onClick={() => {
                            setIsTimeLimited(false);
                            props.change('delegation.deadlineDate', null);
                        }}
                    >
                        {translate('non_les_inscri_87979')}
                    </RadioText>

                    <Spacer height="6" />

                    <Box font="gray800 textSm semiBold">{translate('les_inscription_76218')}</Box>

                    <Spacer height="2" />

                    <RadioText
                        state={isLimited ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setIsLimited(true);
                        }}
                    >
                        {translate('oui_il_y_a_une_02976')}
                    </RadioText>

                    {isLimited && (
                        <>
                            <Spacer height="2" />

                            <TextInput name="delegation.maxResources" shouldParseAsInt={true} />

                            <Spacer height="3" />
                        </>
                    )}

                    <Spacer height="3" />

                    <RadioText
                        state={isLimited ? 'unchecked' : 'checked'}
                        onClick={() => {
                            setIsLimited(false);
                            props.change('delegation.maxResources', null);
                        }}
                    >
                        {translate('non_les_inscri_07566')}
                    </RadioText>
                </FormColumns>

                <HorizontalSpacerSeparator height="5" />

                <FormColumns title={translate('espace_d_l_gati_13152')}>
                    <ToggleText name="delegation.showInDelegationSpace">
                        {translate('afficher_cette_95168')}
                    </ToggleText>

                    {props.values.delegation.showInDelegationSpace && (
                        <>
                            <Spacer height="3" />

                            <ToggleText name="delegation.showFormLinkInDelegationSpace">
                                {translate('afficher_le_lie_05841')}
                            </ToggleText>

                            <Spacer height="6" />

                            <CreateDelegationColumns
                                customFields={props.customFields}
                                delegationType={props.delegationType}
                                values={props.values}
                                change={props.change}
                            />
                        </>
                    )}
                </FormColumns>
            </Flex>
        </>
    );
};
