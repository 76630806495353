import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { ALL_ICON, Icon } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Dropdown } from './dropdown/dropdown';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';
import { IInputProps } from './input/commonInputProps';
import { Description } from './input/description';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

export const StyledIcon = styled('div', {
    alignItems: 'center',
    borderRadius: '$1',
    color: '$gray600',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
    variants: {
        active: {
            true: {
                background: '$primary200',
                color: '$primary700'
            },
            false: {
                '&:hover': {
                    background: '$gray200',
                    color: '$gray800'
                }
            }
        }
    }
});

export interface IIconSelectProps extends IInputProps {
    renderOnPortal?: boolean;
    value: Icon;

    onChange(value: Icon): void;
}

export const IconSelect = ({
    css,
    label,
    description,
    value,
    height: _height,
    renderOnPortal,
    onChange,
    ...rest
}: IIconSelectProps) => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = React.useState(false);
    const icons = React.useMemo(
        () =>
            sortBy(
                ALL_ICON.map((icon) => ({
                    value: icon,
                    name: translate(icon)
                })),
                (i) => i.name
            ),
        []
    );

    return (
        <Flex css={css} direction="column" width={1}>
            <Label>{label}</Label>

            <Description>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <Dropdown isOpen={isOpen} onStateChange={setIsOpen}>
                <Trigger>
                    <StyledInputContainer
                        icon={fromIconVO(value)}
                        rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
                        cursor="pointer"
                        {...rest}
                    >
                        <Box css={{ flex: '1' }}>{translate(value)}</Box>
                    </StyledInputContainer>
                </Trigger>

                <Menu
                    maxHeight={300}
                    placement="bottom"
                    renderOnPortal={renderOnPortal}
                    width="match"
                >
                    <Flex gap="2" wrap="wrap" width={1} css={{ px: '$2' }}>
                        {icons.map((icon) => (
                            <StyledIcon
                                key={icon.value}
                                active={value === icon.value}
                                onClick={() => {
                                    setIsOpen(false);
                                    onChange(icon.value);
                                }}
                            >
                                <I icon={fromIconVO(icon.value)} />
                            </StyledIcon>
                        ))}
                    </Flex>
                </Menu>
            </Dropdown>
        </Flex>
    );
};
